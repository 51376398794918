import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  PieChart,
  LogOut,
  X,
  User,
  Building,
  Database,
  Link as LucideLink,
  File,
  List,
  Book,
  Lock as UserLock,
  Download,
  Archive,
  Coins,
  Menu as MenuIcon,
  LayoutDashboard,
  BookText,
  FileChartColumnIncreasing,
  FileDown,
  FileUp,
  Landmark,
  Users,
  UserRound,
  WashingMachine,
  FileLock,
  BadgeDollarSign,
  FileClock,
} from "lucide-react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Nav,
  Badge,
  Image,
  Button,
  Navbar,
  Modal,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import GmeLogo from "../assets/img/icons/gme-icon.svg";
// import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { getLoginData, removeLoginData } from "../services/authSvc";
import { DashboardTwoTone, MenuUnfoldOutlined } from "@ant-design/icons";

import { Dropdown, Menu } from "antd";
import { DownOutlined, LogoutOutlined, LockOutlined } from "@ant-design/icons";
import userSvc from "../services/userSvc";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export function UserDropdown({ user, handleLogout, handleResetPassword }) {
  // Define dropdown menu items
  const menu = (
    <Menu>
      <Menu.Item
        key="resetPassword"
        icon={<LockOutlined />}
        onClick={handleResetPassword}
      >
        Reset Password
      </Menu.Item>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
        danger
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      {/* 
        Container that displays:
         - Orange circle with number
         - User name with [ADMIN DEV]
         - Subtext with role + green dot
         - Down arrow
      */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          gap: "0.75rem",
        }}
      >
        {/* Orange circle with number 1 */}
        <div
          style={{
            minWidth: 32,
            minHeight: 32,
            borderRadius: "50%",
            backgroundColor: "#FF6F00",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          1
        </div>

        {/* Text container */}
        <div style={{ lineHeight: 1.2 }}>
          <div
            style={{ fontWeight: 600, color: "black" }}
          >{`${user.name}`}</div>
          {/* Subtext: role + green dot */}
          <div
            style={{
              fontSize: 12,
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <span style={{ color: "#888" }}>{user.role}</span>
          </div>
        </div>

        {/* Down arrow icon */}
        <DownOutlined style={{ fontSize: 14, color: "black" }} />
      </div>
    </Dropdown>
  );
}

export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const { user } = getLoginData();

  const onCollapse = () => setShow(!show);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const handleChangePassword = async () => {
    // setShow(false);
    // Perform change password action
    try {
      if (confirmNewPassword !== newPassword) {
        throw new Error("Password dan konfirmasi password tidak sesuai!");
      }

      const data = {
        oldpassword: oldPassword,
        newpassword: newPassword,
      };
      const resp = await userSvc.updateUserPasswordById(user.id_user, data);
      if (resp.status === 200) {
        showSuccessAlert("Berhasil", "Sukses mengubah password");
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setShowChangePasswordModal(false);
      }
    } catch (error) {
      let message = error.response?.data?.message || "Gagal mengubah password";
      showErrorAlert("Gagal", message);
    }
  };

  const toggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible(!isOldPasswordVisible);
  };
  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleLogout = () => {
    removeLoginData();
    window.location.href = "/login";
  };

  const handleResetPassword = () => {
    setShowChangePasswordModal(true);
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";

    // Pengecekan untuk menentukan apakah "NavItem" aktif
    let isActive = link === pathname;

    // Tambahan pengecekan khusus untuk "Infografis Laporan"
    if (title === "Infografis Laporan") {
      isActive =
        pathname === Routes.ReportSummary.path ||
        pathname === Routes.AssetSummary.path ||
        pathname === Routes.OperatorSummary.path;
    }

    if (title === "Pengguna") {
      isActive =
        pathname === Routes.User.path ||
        pathname === Routes.User.path + "/history";
      // pathname === Routes.OperatorSummary.path;
    }

    const navItemClassName = isActive ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon && <span className="sidebar-icon">{icon}</span>}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text text-sm">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        className={`navbar-light px-4 py-2 d-md-none d-flex justify-content-between ${
          show ? "position-static" : "position-sticky top-0"
        }`}
        style={
          !show
            ? {
                zIndex: 1000,
                backgroundColor: "#FFFFFF",
                // borderRadius: "8px",
                boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.1)",
              }
            : {}
        }
      >
        <div className="d-flex align-items-center gap-2">
          <img
            src={GmeLogo}
            alt="logo"
            className="my-2"
            style={{ height: "30px" }}
          />
          <div style={{ fontWeight: 600, color: "black" }}>GME Monitoring</div>
        </div>
        <MenuUnfoldOutlined onClick={onCollapse} style={{ fontSize: "28px" }} />
      </Navbar>

      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block text-white `}
          onWheel={(e) => e.stopPropagation()}
        >
          {show && (
            <div
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#FFFFFF",
                borderBottom: "1px solid grey",
              }}
              className="py-2 px-4 "
            >
              {/* User Card Section */}
              <div className="d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-2">
                {/* <div className="d-flex align-items-center gap-2">
                  <div className="user-avatar lg-avatar ">
                    {user.photo_url ? (
                      <Image
                        src={user.photo_url}
                        className="user-avatar md-avatar rounded-circle"
                      />
                    ) : (
                      <div className="user-avatar md-avatar rounded-circle bg-primary">
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {user.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="text-dark mb-0 ">Hi, {user.name}</h6>
                    <Button
                      onClick={handleLogout}
                      variant="primary"
                      size="xs"
                      className="text-light"
                    >
                      <LogOut size={16} className="me-2" />
                      Sign Out
                    </Button>
                  </div>
                </div> */}
                <UserDropdown
                  user={user}
                  handleLogout={handleLogout}
                  handleResetPassword={handleResetPassword}
                />
                <Nav.Link
                  className="collapse-close d-md-none text-dark"
                  onClick={onCollapse}
                  style={{ padding: "1rem 0" }}
                >
                  <X
                    size={30}
                    onClick={onCollapse}
                    className="cursor-pointer"
                    style={{
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.color = "#f76617")
                    }
                    onMouseLeave={(e) => (e.currentTarget.style.color = "")}
                  />
                </Nav.Link>
              </div>

              {/* Logo and App Name Section */}
              {/* <div
                className="d-flex gap-2 align-items-center justify-content-center text-center pt-3"
                style={{
                  backgroundColor: "#FFFFFF",
                }}
              >
                <img
                  src={GmeLogo}
                  alt="logo"
                  style={{ width: "70px", objectFit: "contain" }}
                />
                <h6 className="text-dark m-0">GME Monitoring</h6>
              </div> */}
            </div>
          )}

          {!show && (
            <div
              className="d-flex gap-2 align-items-center justify-content-center text-center py-3"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#FFFFFF",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <img
                src={GmeLogo}
                alt="logo"
                style={{ width: "40px", objectFit: "contain" }}
              />
              <h6 className="text-dark m-0" style={{ fontWeight: 600 }}>
                GME Monitoring
              </h6>
            </div>
          )}

          <div className="sidebar-inner px-4 pt-3">
            {/* <hr className="mt-2 border-dark px-4" /> */}
            <Nav className="flex-column pt-3 pt-md-0 pb-5">
              {/* <NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} /> */}

              <div className="text-primary mb-2" style={{ fontSize: "14px" }}>
                <strong>Beranda</strong>
              </div>
              {user.role === "operator" ? (
                <>
                  <NavItem
                    title="Upload Laporan"
                    icon={<File size={16} />}
                    link={
                      Routes.OperatorLaporan.path ||
                      Routes.OperatorLaporan.path ||
                      Routes.OperatorLaporan.path
                    }
                  />
                  <NavItem
                    title="Token EMR"
                    icon={<Coins size={16} />}
                    link={Routes.OperatorEmr.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <NavItem
                    title="Dashboard"
                    link={Routes.DashboardOverview.path}
                    icon={<LayoutDashboard size={16} />}
                  />
                  <NavItem
                    title="Laporan"
                    icon={<BookText size={16} />}
                    link={Routes.Report.path}
                  />
                  <NavItem
                    title="Infografis Laporan"
                    icon={<FileChartColumnIncreasing size={16} />}
                    link={
                      Routes.ReportSummary.path ||
                      Routes.AssetSummary.path ||
                      Routes.OperatorSummary.path
                    }
                  />
                  <NavItem
                    title="Download Laporan"
                    icon={<FileDown size={16} />}
                    link={Routes.Export.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role === "admin" || user.role === "admin cabang" ? (
                <NavItem
                  title="Ekspor Link Laporan"
                  icon={<FileUp size={16} />}
                  link={Routes.ExportPDFAsset.path}
                />
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Kelola</strong>
                  </div>
                  <NavItem
                    title="ATM"
                    icon={<WashingMachine size={16} />}
                    link={Routes.Asset.path}
                  />
                  <NavItem
                    title="Pengguna"
                    icon={<User size={16} />}
                    link={
                      Routes.User.path ||
                      Routes.AssetSummary.path ||
                      Routes.OperatorSummary.path
                    }
                  />
                  {user.role === "admin" ? (
                    <>
                      <NavItem
                        title="Role User-ATM"
                        icon={<UserRound size={16} />}
                        link={Routes.UserAsset.path}
                      />
                      <NavItem
                        title="Kantor Cabang"
                        icon={<Landmark size={16} />}
                        link={Routes.Customer.path}
                      />
                      <NavItem
                        title="Jenis Dokumentasi"
                        icon={<FileLock size={16} />}
                        link={Routes.DocType.path}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {user.role !== "operator" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Transaksi</strong>
                  </div>
                  <NavItem
                    title="Transaksi"
                    icon={<BadgeDollarSign size={16} />}
                    link={Routes.report.transaction.path}
                  />
                </>
              ) : (
                ""
              )}

              {user.role === "admin" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>EMR</strong>
                  </div>
                  <NavItem
                    title="EMR Tool"
                    icon={<LucideLink size={16} />}
                    link={Routes.EMR.path}
                  />

                  <NavItem
                    title="Daftar EMR"
                    icon={<List size={16} />}
                    link={Routes.EMRList.path}
                  />
                  <NavItem
                    title="Daftar EMR V2"
                    icon={<List size={16} />}
                    link={Routes.EMRV2List.path}
                  />
                </>
              ) : (
                ""
              )}
              {user.role === "admin" ? (
                <>
                  <hr className="mt-2 border-dark " />
                  <div
                    className="text-primary mb-2"
                    style={{ fontSize: "14px" }}
                  >
                    <strong>Data Logs</strong>
                  </div>
                  <NavItem
                    title="Logs"
                    icon={<FileClock size={16} />}
                    link={Routes.Logs.path}
                  />
                </>
              ) : (
                ""
              )}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>

      {/* Change Password Modal */}
      <Modal
        show={showChangePasswordModal}
        onHide={() => setShowChangePasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ganti Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>Password lama</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isOldPasswordVisible ? "text" : "password"}
                  placeholder="masukkan password lama"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={toggleOldPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isOldPasswordVisible ? faEyeSlash : faEye}
                  />
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>Password Baru</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isNewPasswordVisible ? "text" : "password"}
                  placeholder="Masukkan password baru "
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={toggleNewPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isNewPasswordVisible ? faEyeSlash : faEye}
                  />
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmNewPassword">
              <Form.Label>Konfirmasi Password Baru</Form.Label>
              <InputGroup>
                <Form.Control
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Masukkan konfirmasi password baru"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                <Button
                  variant="outline-primary"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isConfirmPasswordVisible ? faEyeSlash : faEye}
                  />
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowChangePasswordModal(false)}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleChangePassword}>
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
