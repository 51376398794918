import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";
import { Image } from "antd";
import {
  Col,
  Row,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import reportSvc from "../services/reportSvc";
import assetSvc from "../services/assetSvc";
import { getLoginData } from "../services/authSvc";
import { customStyles } from "../utils/SelectUtils";
import Select from "react-select";
import { showWarningAlert } from "../utils/SwalUtil";
import customerSvc from "../services/customerSvc";
import HistoryReportExportByAsset from "../components/HistoryReportExportByAsset";
import HistoryReportExportByPelanggan from "../components/HistoryReportExportByPelanggan";
moment.tz.setDefault("Asia/Bangkok");

export default function ExportPDFAsset() {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [reportExports, setReportExport] = useState([]);
  const [assets, setAssets] = useState([]);
  const [pelanggans, setPelanggans] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedPelanggan, setSelectedPelanggan] = useState(null);
  const [generatedLinkReport, setGeneratedLinkReport] = useState("");
  const [formatReport, setFormatReport] = useState("pelanggan");

  const HandleChangeFormatReport = (format) => {
    setGeneratedLinkReport("");
    setSelectedAsset(null);
    setSelectedPelanggan(null);
    setReportExport([]);
    setFormatReport(format);
  };

  const fetchAssets = async () => {
    const { user } = getLoginData();
    if (user.role === "admin") {
      const resp = await assetSvc.getAsset({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setAssets(resp.data.data);
      }
    }
  };

  const fetchPelanggan = async () => {
    const { user } = getLoginData();
    if (user.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setPelanggans(resp.data.data);
      }
    }
  };

  const fetchReportsExport = async () => {
    try {
      const id_asset = selectedAsset ? selectedAsset.value : "";
      const id_pelanggan = selectedPelanggan ? selectedPelanggan.value : "";
      console.log({ id_asset, id_pelanggan });
      if (id_asset !== "" || id_pelanggan !== "") {
        setIsLoading(true);
        let respdata;
        if (formatReport === "asset") {
          respdata = await reportSvc.getReportExportPDFAsset(id_asset, {
            params: {
              start_date: startDate,
              end_date: endDate,
            },
          });
        }

        if (formatReport === "pelanggan") {
          respdata = await reportSvc.getReportExportPDFPelanggan(id_pelanggan, {
            params: {
              start_date: startDate,
              end_date: endDate,
            },
          });
        }

        if (respdata.status === 200) {
          setReportExport(respdata.data.data);
          setGeneratedLinkReport("");
        }

        setIsLoading(false);
      }
    } catch (err) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchPelanggan();
  }, []);

  useEffect(() => {
    fetchReportsExport();
  }, [startDate, endDate, selectedAsset, selectedPelanggan]);

  const handleStartDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    if (moment(formattedDate).isAfter(endDate)) {
      setEndDate(formattedDate);
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    if (moment(formattedDate).isBefore(startDate)) {
      setStartDate(formattedDate);
    }
  };

  const handleGenerateLink = async () => {
    setGeneratedLinkReport("");
    if (formatReport === "asset") {
      if (startDate && endDate && selectedAsset) {
        setGeneratedLinkReport(
          `/report-asset?id=${selectedAsset.value}&startdate=${startDate}&enddate=${endDate}`
        );
      } else {
        showWarningAlert("Gagal", "Pilih ATM Terlebih dahulu");
      }
    }

    if (formatReport === "pelanggan") {
      if (startDate && endDate && selectedPelanggan) {
        setGeneratedLinkReport(
          `/report-cabang?id=${selectedPelanggan.value}&startdate=${startDate}&enddate=${endDate}`
        );
      } else {
        showWarningAlert("Gagal", "Pilih Kantor Cabang Terlebih dahulu");
      }
    }
  };

  const handleSelectAsset = (selectedOption) => {
    console.log("asset berubah", selectedOption);
    setSelectedAsset(selectedOption);
  };

  const handleSelectPelanggan = (selectedOption) => {
    setSelectedPelanggan(selectedOption);
  };

  const handleCopy = () => {
    const el = document.createElement("textarea");
    const domain = window.location.origin;
    el.value = domain + generatedLinkReport;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);

    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;

    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    alert("Link copied!");
  };

  return (
    <>
      <div className="main-content-page">
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <div className="mt-3 row align-items-center">
              <h5 className="col mb-0">Generate Link Laporan ATM</h5>
              <div className="col-auto">
                <button
                  style={{
                    border: "1px solid #f76617",
                    backgroundColor:
                      formatReport === "asset" ? "#f76617" : "transparent",
                    color: formatReport === "asset" ? "#ffffff" : "#f76617",
                    borderRadius: "4px",
                    marginRight: "10px",
                  }}
                  onClick={() => HandleChangeFormatReport("asset")}
                >
                  Per-ATM
                </button>
                <button
                  style={{
                    border: "1px solid #f76617",
                    backgroundColor:
                      formatReport === "pelanggan" ? "#f76617" : "transparent",
                    color: formatReport === "pelanggan" ? "#ffffff" : "#f76617",
                    borderRadius: "4px",
                  }}
                  onClick={() => HandleChangeFormatReport("pelanggan")}
                >
                  Per-Cabang
                </button>
              </div>
            </div>
            <hr />
            <Row className="align-items-center">
              <Form>
                <div className="mb-4">
                  <Form.Group id="range">
                    <Form.Label className="text-primary fs-6">
                      Pilih rentang tanggal
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Label>Tanggal dimulai</Form.Label>
                        <Datetime
                          timeFormat={false}
                          onChange={handleStartDateChange}
                          value={startDate}
                          renderInput={(_props, openCalendar) => (
                            <InputGroup className="me-2">
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={startDate}
                                placeholder="YYYY-MM-DD"
                                onFocus={openCalendar}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          )}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Tanggal berakhir</Form.Label>
                        <Datetime
                          timeFormat={false}
                          onChange={handleEndDateChange} // Call handleEndDateChange with the selected date
                          value={endDate}
                          renderInput={(props, openCalendar) => (
                            <InputGroup className="me-2">
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={endDate}
                                placeholder="YYYY-MM-DD"
                                onFocus={openCalendar}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          )}
                        />
                      </Col>
                      {formatReport === "asset" && (
                        <Col>
                          <Form.Label>ATM</Form.Label>
                          <InputGroup>
                            <Select
                              className="me-1"
                              id="state"
                              placeholder="Pilih ATM"
                              styles={{
                                ...customStyles,
                                input: (provided) => ({
                                  ...provided,
                                  minHeight: "36px",
                                }),
                              }}
                              value={selectedAsset}
                              onChange={handleSelectAsset}
                              options={assets?.map((asset) => ({
                                value: asset?.id_asset,
                                label: asset?.name,
                              }))}
                            />
                          </InputGroup>
                        </Col>
                      )}

                      <Col
                        className={
                          formatReport === "asset" ? "visually-hidden" : null
                        }
                      >
                        <Form.Label>Cabang</Form.Label>
                        <InputGroup>
                          <Select
                            className="me-1"
                            id="state"
                            placeholder="Pilih Cabang"
                            styles={{
                              ...customStyles,
                              input: (provided) => ({
                                ...provided,
                                minHeight: "36px",
                              }),
                            }}
                            value={selectedPelanggan}
                            onChange={handleSelectPelanggan}
                            options={pelanggans?.map((pelanggan) => ({
                              value: pelanggan?.id_pelanggan,
                              label: pelanggan?.name,
                            }))}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                </div>
              </Form>
              <Col className="text-start">
                <Button
                  variant="primary"
                  size="md"
                  onClick={handleGenerateLink}
                >
                  Generate Link
                </Button>
              </Col>
            </Row>
          </Card.Header>
          {generatedLinkReport === "" ? (
            ""
          ) : (
            <Card.Footer>
              <>
                <p>Berikut adalah link yang telah anda generate</p>
                <a
                  href={generatedLinkReport}
                  rel="noopener noreferrer"
                  className="fw-bolder"
                  target="_blank"
                >
                  {`${generatedLinkReport}`}
                </a>
                <FontAwesomeIcon
                  icon={faCopy}
                  onClick={handleCopy}
                  style={{
                    marginLeft: "20px",
                    color: "#f76617",
                    cursor: "pointer",
                  }}
                />
              </>
            </Card.Footer>
          )}
        </Card>
      </div>

      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {reportExports.endDate ? (
            <>
              {formatReport === "asset" && (
                <HistoryReportExportByAsset reportExports={reportExports} />
              )}
              {formatReport === "pelanggan" && (
                <HistoryReportExportByPelanggan reportExports={reportExports} />
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
