import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import reportSvc from "../../services/reportSvc";
import { getLoginData } from "../../services/authSvc";
import NavTabs from "../../components/NavTabs";
import { Table } from "@themesberg/react-bootstrap";
import { Skeleton, Button, Card } from "antd";
import {
  Download,
  RefreshCw,
  FileText,
  Home,
  Users,
  PieChart,
} from "lucide-react";
import DashboardInfografisLaporan from "../../components/DashboardInfografisLaporan";

moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [customers, setCustomers] = useState([]);
  const history = useHistory();

  const { user } = getLoginData();

  const handleTabChange = (path) => {
    history.push(path);
  };

  // Fetch data from the backend
  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const resp = await reportSvc.getAssetSummaryReport({
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  // Calculate dynamic summary stats
  const totalBranches = customers.length;
  const totalBelumDikerjakan = customers.reduce(
    (acc, c) => acc + (c.belum_dikerjakan || 0),
    0
  );
  const totalAtmAktif = customers.reduce(
    (acc, c) => acc + (c.total_atm_active || 0),
    0
  );
  const branchesWithIssues = customers.filter(
    (c) => (c.belum_dikerjakan || 0) > 0
  ).length;

  // Example: percentage of branches with no pending issues
  const completionRate =
    totalBranches === 0
      ? 0
      : Math.round(
          ((totalBranches - branchesWithIssues) / totalBranches) * 100
        );

  return (
    <div className="main-content-page">
      <DashboardInfografisLaporan></DashboardInfografisLaporan>
      <Card style={{ width: "100%" }}>
        <NavTabs
          activeTab={"/asset-summary"}
          onTabChange={handleTabChange}
          sideTab={"infografis"}
        />
        <br />
        <div style={{ overflowX: "auto", display: "visible" }}>
          {isLoading ? (
            <Skeleton.Input
              active
              block
              style={{ width: "100%", height: "80vh", marginBottom: "1rem" }}
            />
          ) : (
            <div
              style={{
                maxHeight: "60vh",
                overflowY: "auto",
              }}
              className="custom-scrollbar"
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th>Kantor Cabang</th>
                    <th>Belum Dikerjakan</th>
                    <th>Total ATM Aktif</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer) => (
                    <tr key={customer.id_pelanggan}>
                      <td>{customer.name}</td>
                      <td>{customer.belum_dikerjakan}</td>
                      <td>{customer.total_atm_active}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
