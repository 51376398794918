import React from "react";
import { Tabs } from "antd";
import { useHistory } from "react-router-dom";
import {
  FileText,
  Home,
  Users,
  User,
  Clock,
  Plus,
  Search,
  List,
} from "lucide-react";

const { TabPane } = Tabs;

const tabFactory = {
  infografis: [
    {
      label: "Report Summary",
      path: "/report-summary",
      icon: <FileText strokeWidth={1} size="1rem" />,
    },
    {
      label: "Asset Summary",
      path: "/asset-summary",
      icon: <Home strokeWidth={1} size="1rem" />,
    },
    {
      label: "Operator Summary",
      path: "/operator-summary",
      icon: <Users strokeWidth={1} size="1rem" />,
    },
  ],
  user: [
    {
      label: "Pengguna",
      path: "/user",
      icon: <User strokeWidth={1} size="1rem" />,
    },
    {
      label: "History Pengguna",
      path: "/user/history",
      icon: <Clock strokeWidth={1} size="1rem" />,
    },
    {
      label: "Tambah Pengguna",
      path: "/user/add",
      icon: <Plus strokeWidth={1} size="1rem" />,
    },
  ],
  operator: [
    {
      label: "Cari ATM",
      path: "/operator/laporan",
      icon: <Search strokeWidth={1} size="1rem" />,
    },
    {
      label: "Riwayat Laporan",
      path: "/operator/laporan/view",
      icon: <List strokeWidth={1} size="1rem" />,
    },
  ],
};

const NavTabs = ({ activeTab, sideTab }) => {
  const history = useHistory();
  const tabs = tabFactory[sideTab] || [];

  const handleTabChange = (key) => {
    history.push(key);
  };

  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        type="card"
        tabBarStyle={{ marginBottom: "0px" }}
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.label} key={tab.path} icon={tab.icon} />
        ))}
      </Tabs>
    </div>
  );
};

export default NavTabs;
