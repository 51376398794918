import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import userAssetSvc from "../services/userAssetSvc";
import customerSvc from "../services/customerSvc";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";
export default () => {
  const [keterangan, setKeterangan] = useState("");
  const [userAsset, setUserAsset] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [customerValues, setCustomerValues] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      fetchUserAssetById();
    }
    fetchCustomers();
  }, []);

  const handleCustomerChange = (e, index) => {
    const newValue = e.target.value;
    setCustomerValues((prevValues) => {
      const newValues = [...prevValues]; // Create a new array based on the previous values
      if (newValues.length > index) {
        newValues[index] = newValue; // Update the value at the specified index
      } else {
        newValues.push(newValue); // Add a new value at the end of the array
      }
      return newValues; // Return the updated array
    });
  };

  const fetchCustomers = async () => {
    setIsLoading(true);
    const resp = await customerSvc.getCustomer({
      params: {
        page: 0,
        limit: 10000,
      },
    });
    if (resp.status === 200) {
      setCustomers(resp.data.data);
    }
    setIsLoading(false);
  };

  const fetchUserAssetById = async () => {
    setIsLoading(true);
    const resp = await userAssetSvc.getUserAssetById(id);
    if (resp.status === 200) {
      const userAssetData = resp.data.data;
      setUserAsset(userAssetData);
      setKeterangan(userAssetData.keterangan);
      const pelangganValues = [
        userAssetData.pelanggan1 || null,
        userAssetData.pelanggan2 || null,
        userAssetData.pelanggan3 || null,
        userAssetData.pelanggan4 || null,
        userAssetData.pelanggan5 || null,
      ];

      setCustomerValues(pelangganValues);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    if (!keterangan || customerValues.length === 0) {
      showErrorAlert("Gagal", "Kolom harus diisi lengkap");
      return;
    }

    if (id) {
      try {
        const data = {
          keterangan: keterangan,
        };

        for (let i = 0; i < customerValues.length; i++) {
          if (customerValues[i]) {
            if (customerValues[i].id_pelanggan) {
              const key = `id_pelanggan${i + 1}`;
              data[key] = customerValues[i].id_pelanggan;
            } else {
              const key = `id_pelanggan${i + 1}`;
              data[key] = parseInt(customerValues[i]);
            }
          }
        }

        const resp = await userAssetSvc.updateUserAsset(id, data);
        if (resp.status === 200) {
          showSuccessAlert("Berhasil", "Data user aset berhasil diubah");
          history.goBack();
        } else {
          let message =
            resp?.response?.data?.message || "Gagal mengubah data user aset";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message =
          error.response.data.message || "Gagal mengubah data user aset";
        showErrorAlert("Gagal", message);
      }
    } else {
      try {
        const data = {
          keterangan: keterangan,
        };
        for (let i = 0; i < customerValues.length; i++) {
          const key = `id_pelanggan${i + 1}`;
          data[key] = customerValues[i];
        }
        const resp = await userAssetSvc.addUserAsset(data);
        if (resp.status === 200) {
          showSuccessAlert("Berhasil", "Data user aset berhasil ditambahkan");
          history.goBack();
        } else {
          let message =
            resp?.response?.data?.message ||
            "Gagal menambahkan data user asset";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message =
          error?.response?.data?.message || "Gagal mengubah data user aset";
        showErrorAlert("Gagal", message);
      }
    }
  };

  return (
    <div className="main-content-page">
      <Card>
        <Card.Header>
          <Card.Title className="h6">
            {id ? "Ubah User Asset" : "Tambah User Asset"}
          </Card.Title>
        </Card.Header>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nama User Asset</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan nama user asset"
                    value={keterangan}
                    onChange={(e) => setKeterangan(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3 mt-2">
                <Form.Label>Pelanggan 1</Form.Label>
                <Form.Select
                  id="state"
                  required
                  defaultValue={
                    customerValues[0] !== undefined
                      ? customerValues.id_pelanggan
                      : "no customer linked"
                  }
                  value={
                    customerValues[0] ? customerValues[0].id_pelanggan : null
                  }
                  onChange={(e) => handleCustomerChange(e, 0)}
                >
                  <option key={"no cust"} value={null}>
                    No customer linked
                  </option>
                  {customers.map((customer) => (
                    <option
                      key={customer.id_pelanggan}
                      value={customer.id_pelanggan}
                    >
                      {customer.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3 mt-2">
                <Form.Label>Pelanggan 2</Form.Label>
                <Form.Select
                  id="state"
                  required
                  defaultValue={
                    customerValues[1] !== undefined
                      ? customerValues.id_pelanggan
                      : "no customer linked"
                  }
                  value={
                    customerValues[1] ? customerValues[1].id_pelanggan : null
                  }
                  onChange={(e) => handleCustomerChange(e, 1)}
                >
                  <option key={"no cust"} value={null}>
                    No customer linked
                  </option>
                  {customers.map((customer) => (
                    <option
                      key={customer.id_pelanggan}
                      value={customer.id_pelanggan}
                    >
                      {customer.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {customerValues.length > 1 && (
                <Form.Group className="mb-3 mt-2">
                  <Form.Label>Pelanggan 3</Form.Label>
                  <Form.Select
                    id="state"
                    required
                    defaultValue={
                      customerValues[2] !== undefined
                        ? customerValues.id_pelanggan
                        : "no customer linked"
                    }
                    value={
                      customerValues[2] ? customerValues[2].id_pelanggan : null
                    }
                    onChange={(e) => handleCustomerChange(e, 2)}
                  >
                    <option key={"no cust"} value={null}>
                      No customer linked
                    </option>
                    {customers.map((customer) => (
                      <option
                        key={customer.id_pelanggan}
                        value={customer.id_pelanggan}
                      >
                        {customer.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {customerValues.length > 2 && (
                <Form.Group className="mb-3 mt-2">
                  <Form.Label>Pelanggan 4</Form.Label>
                  <Form.Select
                    id="state"
                    required
                    defaultValue={
                      customerValues[3] !== undefined
                        ? customerValues.id_pelanggan
                        : "no customer linked"
                    }
                    value={
                      customerValues[3] ? customerValues[3].id_pelanggan : null
                    }
                    onChange={(e) => handleCustomerChange(e, 3)}
                  >
                    <option key={"no cust"} value={null}>
                      No customer linked
                    </option>
                    {customers.map((customer) => (
                      <option
                        key={customer.id_pelanggan}
                        value={customer.id_pelanggan}
                      >
                        {customer.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {customerValues.length > 3 && (
                <Form.Group className="mb-3 mt-2">
                  <Form.Label>Pelanggan 5</Form.Label>
                  <Form.Select
                    id="state"
                    required
                    defaultValue={
                      customerValues[4] !== undefined
                        ? customerValues.id_pelanggan
                        : "no customer linked"
                    }
                    value={
                      customerValues[4] ? customerValues[4].id_pelanggan : null
                    }
                    onChange={(e) => handleCustomerChange(e, 4)}
                  >
                    <option key={"no cust"} value={null}>
                      No customer linked
                    </option>
                    {customers.map((customer) => (
                      <option
                        key={customer.id_pelanggan}
                        value={customer.id_pelanggan}
                      >
                        {customer.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </Form>
          </Card.Body>
        )}

        <Card.Footer>
          <Button
            variant="secondary"
            className="me-2"
            onClick={() => history.goBack()}
          >
            Batalkan
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
};
