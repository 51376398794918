import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  Table,
  Space,
  Menu,
  Dropdown,
  Badge,
  Pagination,
  Spin,
} from "antd";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import userSvc from "../../services/userSvc";
import customerSvc from "../../services/customerSvc";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../../utils/SwalUtil";
import { getLoginData } from "../../services/authSvc";
import { customStyles } from "../../utils/SelectUtils";
import NavTabs from "../../components/NavTabs";
import reportSvc from "../../services/reportSvc";
import { SearchOutlined } from "@ant-design/icons";
import DataTable from "../../components/template/DataTable";
import { ColumnHistoryPengguna } from "../../template/TemplateColumn";
const { Option } = Select;

export default () => {
  const searchInputRef = useRef(null);
  const [historyUsers, setHistoryUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedRole, setSelectedRole] = useState("");

  const history = useHistory();
  const dev = process.env.REACT_APP_UI_DEV;
  const { user } = getLoginData();
  const role = user?.role;

  const getCustomers = async () => {
    if (role === "admin") {
      const respCust = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });

      if (respCust.status === 200) {
        setCustomers(respCust.data.data);
      } else {
        setCustomers([]);
      }
    }
  };

  const fetchHistoryUsers = async () => {
    setIsLoading(true);

    const resp = await reportSvc.getUserHistory({
      params: {
        page: page,
        limit: 1000,
        query: searchTerm,
        id_pelanggan: selectedCustomer,
        role: selectedRole,
      },
    });
    if (resp.status === 200) {
      setHistoryUsers(resp.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    fetchHistoryUsers();
  }, [selectedCustomer, selectedRole]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchHistoryUsers();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 1000);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedCustomer(null);
    setSelectedRole("");

    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const handleTabChange = () => {
    useHistory.push("/");
  };

  const columns = ColumnHistoryPengguna();

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    showQuickJumper: false,
    align: "center",
  };

  return (
    <div className="main-content-page">
      <CardAntd style={{ width: "100%" }}>
        <NavTabs
          activeTab={"/user/history"}
          onTabChange={handleTabChange}
          sideTab={"user"}
        />
        <br />
        <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            <Input
              size="middle"
              placeholder="Cari nama ATM, Lokasi, Kode, dll..."
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="me-1"
              ref={searchInputRef}
            />
          </Input.Group>
          <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
            {" "}
            <Select
              value={selectedRole}
              onChange={setSelectedRole}
              placeholder="Semua Role"
              style={{ width: "100%" }}
            >
              <Option value="">Semua Role</Option>
              <Option value="admin">Admin</Option>
              <Option value="admin cabang">Admin Cabang</Option>
              <Option value="operator">Operator</Option>
            </Select>
          </Input.Group>
          {user?.role === "admin" && (
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                placeholder="Semua Kantor Cabang"
                allowClear
                showSearch
                onDropdownVisibleChange={() => {
                  if (customers.length === 0) getCustomers();
                }}
                onChange={handleSelectCustomer}
                value={selectedCustomer}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "100%", height: "100%" }}
                size="middle"
                notFoundContent={
                  customers.length == 0 ? (
                    <Spin size="small" />
                  ) : (
                    "No Data Found"
                  )
                } // Display loading spinner or fallback message
              >
                {customers.map((data) => (
                  <Select.Option
                    key={data.id_pelanggan}
                    value={data.id_pelanggan}
                  >
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </Input.Group>
          )}

          <Button
            variant="secondary"
            size="sm"
            className="ml-2"
            style={{ height: "auto" }}
            onClick={(e) => {
              handleClearFilters();
            }}
          >
            Reset Filter
          </Button>
        </div>
        <DataTable
          columns={columns}
          dataSource={historyUsers}
          loading={isLoading}
          pagination={{}}
        />
      </CardAntd>
    </div>
  );
};
