import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
// import {
//   Button,
//   Card,
//   Table,
//   Form,
//   InputGroup,
//   Image,
//   Spinner,
//   Pagination,
//   Nav,
//   FormControl,
// } from "@themesberg/react-bootstrap";

import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  Table,
  Space,
  Menu,
  Dropdown,
  Badge,
  Pagination,
  Spin,
} from "antd";

import { useEffect } from "react";
import customerSvc from "../services/customerSvc";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";
import DataTable from "../components/template/DataTable";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnPelanggan } from "../template/TemplateColumn";
const { Option } = Select;

export default () => {
  const searchInputRef = useRef(null);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const history = useHistory();
  const { user } = getLoginData();
  const role = user?.role;
  const dev = process.env.REACT_APP_UI_DEV;

  const fetchCustomers = async () => {
    setIsLoading(true);
    const resp = await customerSvc.getCustomer({
      params: {
        query: searchTerm,
        active: selectedStatus,
        page: page,
        limit: pageSize,
      },
    });
    if (resp.status === 200) {
      setCustomers(resp.data.data);
      setTotalRows(resp.data.totalRows);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCustomers();
  }, [selectedStatus, page]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchCustomers();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleActive = async (id) => {
    const willUpdate = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah status kantor cabang ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      cancelButtonText: "Cancel",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status kantor cabang...");
        const resp = await customerSvc.updateActivateCustomer(id);
        if (resp.status === 200) {
          fetchCustomers();
          showSuccessAlert("Behasil", "Berhasil merubah status");
        }
      } catch (error) {
        let message =
          error.response?.data?.message ||
          "Gagal mengubah status kantor cabang";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddCustomer = () => {
    history.push("/customer/add");
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedStatus(null);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    // fetchCustomers();
  };

  const handleEditCustomer = async (customerId) => {
    history.push(`/customer/update/${customerId}`);
  };

  const columns = ColumnPelanggan(
    history,
    handleEditCustomer,
    handleActive,
    role,
    dev
  );

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    showQuickJumper: false,
    align: "center",
  };

  return (
    <>
      <div className="main-content-page">
        <CardAntd
          title={<span style={{ fontSize: "20px" }}>Kantor Cabang</span>}
          extra={
            <div className="d-flex">
              {role === "admin" && dev === "developer" && (
                <div className="d-flex gap-2 ">
                  <Tooltip title={"Tambah Cabang"}>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={handleAddCustomer}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ fontSize: "14px" }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          }
          style={{ width: "100%" }}
        >
          <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Input
                size="middle"
                placeholder="Cari nama ATM, Lokasi, Kode, dll..."
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                className="me-1"
                ref={searchInputRef}
              />
            </Input.Group>

            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                id="state"
                value={selectedStatus}
                onChange={(value) => setSelectedStatus(value)}
                placeholder="Semua Status"
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                <Option value="">Semua Status</Option>
                <Option value="true">Status Aktif</Option>
                <Option value="false">Status Nonaktif</Option>
              </Select>
            </Input.Group>

            <Button
              variant="secondary"
              size="sm"
              className="ml-2"
              style={{ height: "auto" }}
              onClick={(e) => {
                handleClearFilters();
              }}
            >
              Reset Filter
            </Button>
          </div>
          <DataTable
            columns={columns}
            dataSource={customers}
            loading={isLoading}
            pagination={pagination}
          />
        </CardAntd>
      </div>
    </>
  );
};
