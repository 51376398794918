import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import customerSvc from "../services/customerSvc";
import { useParams, useHistory } from "react-router";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";

export default () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [information, setInformation] = useState("");
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchCustomerById();
    }
  }, []);

  const fetchCustomerById = async () => {
    try {
      setIsLoading(true);
      const resp = await customerSvc.getCustomerById(id);
      if (resp.status === 200) {
        setName(resp.data.data.name);
        setEmail(resp.data.data.email);
        setPhone(resp.data.data.phone);
        setAddress(resp.data.data.alamat);
        setInformation(resp.data.data.keterangan);
      }
    } catch (error) {
      showErrorAlert("Error Fetching", "Gagal mendapatkan data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      if (!name || !email || !phone || !address) {
        showErrorAlert("Gagal", "Kolom harus diisi lengkap");
        return;
      }
      if (id) {
        const data = {
          name: name,
          email: email,
          phone: phone,
          alamat: address,
          keterangan: information,
        };

        const resp = await customerSvc.updateCustomer(id, data);
        if (resp.status === 200) {
          showSuccessAlert("Berhasil", "Data kantor cabang berhasil diubah");
          history.goBack();
        } else {
          let message =
            resp?.response?.data?.message ||
            "Gagal mengubah data kantor cabang";
          showErrorAlert("Gagal", message);
        }
      } else {
        const data = {
          name: name,
          email: email,
          phone: phone,
          alamat: address,
          keterangan: information,
        };

        // add new customer
        const resp = await customerSvc.addCustomer(data);
        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            "Data kantor cabang berhasil ditambahkan"
          );
          history.goBack();
        } else {
          let message =
            resp?.response?.data?.message || "Gagal membuat kantor cabang";
          showErrorAlert("Gagal", message);
        }
      }
    } catch (error) {
      let message =
        error.response?.data?.message ||
        "Gagal menambah/mengubah data kantor cabang";
      showErrorAlert("Gagal", message);
    }
  };

  return (
    <div className="main-content-page">
      <Card>
        <Card.Header>
          <Card.Title className="h6">
            {id ? "Ubah Pelanggan" : "Tambah Pelanggan"}
          </Card.Title>
        </Card.Header>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nama Pelanggan</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan Nama"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Masukkan Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Nomor Telepon</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan Nomor Telepon"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Alamat</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan Alamat"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Keterangan Tambahan</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="tex"
                    placeholder="Masukkan Keterangan"
                    value={information}
                    onChange={(e) => setInformation(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
          </Card.Body>
        )}

        <Card.Footer>
          <Button
            className="mx-2"
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Kembali
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan Perubahan
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
};
