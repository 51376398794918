import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  Card as CardAntd,
  Input,
  Tooltip,
  Select,
  Button,
  Tag,
  Table,
  Space,
  Menu,
  Dropdown,
  Badge,
  Pagination,
  Spin,
} from "antd";

import { useEffect } from "react";
import Swal from "sweetalert2";

import docTypeSvc from "../services/docTypeSvc";

import { useHistory } from "react-router-dom";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import DataTable from "../components/template/DataTable";
import { ColumnJenisDokumentasi } from "../template/TemplateColumn";
import { getLoginData } from "../services/authSvc";
import customerSvc from "../services/customerSvc";
const { Option } = Select;

export default () => {
  const [doctType, setDocType] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTypeDoc, setSelectedTypeDoc] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const { role } = getLoginData().user;
  const history = useHistory();

  const fetchDocType = async () => {
    setIsLoading(true);
    const resp = await docTypeSvc.getJenisDokumentasi({
      params: {
        active: selectedStatus,
        id_pelanggan: selectedCustomer,
        type_doc: selectedTypeDoc,
        // query: searchTerm,
      },
    });
    if (resp.status === 200) {
      setDocType(resp.data.data);
    }
    setIsLoading(false);
  };

  const fetchCustomers = async () => {
    if (role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: { page: 0, limit: 1000 },
      });
      setCustomers(resp?.status === 200 ? resp.data.data : []);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    fetchDocType();
  }, [selectedStatus, selectedCustomer, selectedTypeDoc]);

  const handleActive = async (id, activeData) => {
    const willUpdate = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah status jenis dokumentasi ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes!",
      cancelButtonText: "Cancel",
    });

    if (willUpdate.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Mengubah status jenis dokumentasi...");
        const data = {
          update_activate: "true",
        };
        const resp = await docTypeSvc.updateActivateJenisDokumentasi(id, data);
        if (resp.status === 200) {
          fetchDocType();
          showSuccessAlert(
            "Berhasil",
            "Status jenis dokumentasi berhasil diubah"
          );
        }
      } catch (error) {
        let message =
          error.response?.data?.message ||
          "Gagal mengubah status jenis dokumentasi";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleDelete = async (id) => {
    const willDelete = await Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus data jenis dokumentasi ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (willDelete.isConfirmed) {
      try {
        showLoadingAlert("Tunggu", "Menghapus data jenis dokumentasi");
        const resp = await docTypeSvc.deleteJenisDokumentasi(id);
        fetchDocType();
        showSuccessAlert("Berhasil", "Data jenis dokumentasi berhasil dihapus");
      } catch (error) {
        let message =
          error.response?.data?.message ||
          "Gagal menghapus data jenis dokumentasi";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const handleAddDocType = () => {
    history.push("/doctype/add");
  };

  const handleEditDocType = async (id) => {
    history.push(`/doctype/edit/${id}`);
  };

  const columns = ColumnJenisDokumentasi(
    history,
    handleEditDocType,
    handleDelete,
    handleActive,
    "admin",
    "developer",
    customers
  );

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePageSizeChange = (current, pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  };

  const pagination = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: pageSize,
    total: totalRows,
    onChange: handlePageChange,
    onShowSizeChange: handlePageSizeChange,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    showQuickJumper: false,
    align: "center",
  };

  return (
    <>
      <div className="main-content-page">
        <CardAntd
          title={<span style={{ fontSize: "20px" }}>Jenis Dokumentasi</span>}
          extra={
            <div className="d-flex">
              <div className="d-flex gap-2 ">
                <Tooltip title={"Tambah Dokumentasi"}>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={handleAddDocType}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ fontSize: "14px" }}
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
          }
          style={{ width: "100%" }}
        >
          <div className="d-flex flex-wrap justify-content-between mb-3 gap-2">
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                id="state"
                value={selectedStatus}
                allowClear
                onChange={(value) => setSelectedStatus(value)}
                placeholder="Semua Status"
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                {/* <Option value="">Semua Status</Option> */}
                <Option value="true">Status Aktif</Option>
                <Option value="false">Status Nonaktif</Option>
              </Select>
            </Input.Group>
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              <Select
                id="state"
                value={selectedTypeDoc}
                allowClear
                onChange={(value) => setSelectedTypeDoc(value)}
                placeholder="Semua Tipe Dokumentasi"
                style={{ width: "100%", height: "100%" }}
                size="middle"
              >
                {/* <Option value="null">Semua Tipe</Option> */}
                <Option value="image">Gambar</Option>
                <Option value="video">Video</Option>
              </Select>
            </Input.Group>
            <Input.Group style={{ flexGrow: 1, flexBasis: "300px" }}>
              {role === "admin" && (
                <Select
                  placeholder="Semua Kantor Cabang"
                  allowClear
                  showSearch
                  onChange={setSelectedCustomer}
                  value={selectedCustomer}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  style={{ width: "100%" }}
                >
                  {customers.map(({ id_pelanggan, name }) => (
                    <Option key={id_pelanggan} value={id_pelanggan}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
            </Input.Group>
          </div>
          <DataTable
            columns={columns}
            dataSource={doctType}
            loading={isLoading}
            pagination={{}}
          />
        </CardAntd>
      </div>
    </>
  );
};
