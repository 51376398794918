import React, { useState, useEffect } from "react";
import { Form, Spinner } from "@themesberg/react-bootstrap";
import Swal from "sweetalert2";
import docTypeSvc from "../services/docTypeSvc";
import { useHistory, useParams } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";
import customerSvc from "../services/customerSvc";
import { Select, Card as CardAntd, Button } from "antd";

export default ({ docTypeEdit }) => {
  const [docTypeName, setDocTypeName] = useState("");
  const [typeDoc, setTypeDoc] = useState("image");
  const [idPelanggan, setIdPelanggan] = useState(null); // stored as array
  const [docType, setDocType] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { role } = getLoginData().user;
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchDocTypeId();
    }
  }, [docTypeEdit]);

  const fetchCustomers = async () => {
    if (role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: { page: 0, limit: 1000 },
      });
      setCustomers(resp?.status === 200 ? resp.data.data : []);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchDocTypeId = async () => {
    setIsLoading(true);
    const resp = await docTypeSvc.getJenisDokumentasiById(id);
    if (resp.status === 200) {
      const data = resp.data.data;
      setDocType(data);
      setDocTypeName(data.nama_jenis_dokumentasi);
      setTypeDoc(data.type_doc);
      setIdPelanggan(
        data.id_pelanggan && data.id_pelanggan.length > 0
          ? data.id_pelanggan
          : null
      );
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    if (!docTypeName) {
      showErrorAlert("Gagal", "Kolom harus diisi lengkap");
      return;
    }
    const payload = {
      nama_jenis_dokumentasi: docTypeName,
      type_doc: typeDoc,
      id_pelanggan: idPelanggan && idPelanggan.length > 0 ? idPelanggan : null,
    };

    if (id) {
      try {
        const resp = await docTypeSvc.updateJenisDokumentasi(id, payload);
        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            "Data jenis dokumentasi berhasil diubah"
          );
          history.goBack();
        } else {
          const message =
            resp?.response?.data?.message ||
            "Gagal mengubah data jenis dokumentasi";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        const message =
          error.response?.data?.message ||
          "Gagal mengubah data jenis dokumentasi";
        showErrorAlert("Gagal", message);
      }
    } else {
      try {
        const resp = await docTypeSvc.addJenisDokumentasi(payload);
        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            "Data jenis dokumentasi berhasil ditambah"
          );
          history.push("/doctype");
        } else {
          const message =
            resp?.response?.data?.message ||
            "Gagal menambahkan data jenis dokumentasi";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        const message =
          error.response?.data?.message ||
          "Gagal menambahkan data jenis dokumentasi";
        showErrorAlert("Gagal", message);
      }
    }
  };

  return (
    <div className="main-content-page">
      <CardAntd
        title={
          <span style={{ fontSize: "20px" }}>
            {id ? "Ubah Jenis Dokumentasi" : "Tambah Jenis Dokumentasi"}
          </span>
        }
        extra={<div className="d-flex"></div>}
        style={{ width: "100%" }}
      >
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nama Dokumentasi</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Masukkan nama dokumentasi"
                  value={docTypeName}
                  onChange={(e) => setDocTypeName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Tipe Dokumentasi</Form.Label>
                <Select
                  size="large"
                  value={typeDoc}
                  onChange={(value) => setTypeDoc(value)}
                  style={{ width: "100%" }}
                >
                  <Select.Option value="image">Gambar</Select.Option>
                  <Select.Option value="video">Video</Select.Option>
                </Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Akan Digunakan Cabang</Form.Label>
                <Select
                  mode="multiple"
                  size="large"
                  placeholder="Pilih Cabang"
                  value={idPelanggan}
                  onChange={(value) => setIdPelanggan(value)}
                  style={{ width: "100%" }}
                >
                  {customers.map((customer) => (
                    <Select.Option
                      key={customer.id_pelanggan}
                      value={customer.id_pelanggan}
                    >
                      {customer.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Group>
            </Form>
            <div style={{ textAlign: "right", marginTop: 16 }}>
              <Button
                variant="solid"
                type="primary"
                size="large"
                onClick={handleSave}
              >
                Simpan Perubahan
              </Button>
            </div>
          </>
        )}
      </CardAntd>
    </div>
  );
};
