import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";

import {
  Col,
  Row,
  Button,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  FormControl,
} from "@themesberg/react-bootstrap";
import reportSvc from "../../services/reportSvc";

import { getLoginData } from "../../services/authSvc";
import customerSvc from "../../services/customerSvc";
import { customStyles } from "../../utils/SelectUtils";
import Select from "react-select";
import NavTabs from "../../components/NavTabs";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import DashboardInfografisLaporan from "../../components/DashboardInfografisLaporan";
moment.tz.setDefault("Asia/Bangkok");

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formatDate, setFormatDate] = useState("day");
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(5, formatDate === "day" ? "days" : "months")
      .format(formatDate === "day" ? "YYYY-MM-DD" : "YYYY-MM")
  );
  const [searchTerm, setSearchTerm] = useState("");

  const searchInputRef = useRef(null);
  const [endDate, setEndDate] = useState(
    moment().format(formatDate == "day" ? "YYYY-MM-DD" : "YYYY-MM")
  );
  const { user } = getLoginData();
  const [reportExports, setReportExport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(
    user.role === "admin cabang" ? user.id_pelanggan : ""
  );
  const [daysInRange, setDaysInRange] = useState();

  const fetchCustomers = async () => {
    if (user.role === "admin") {
      const resp = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    }
  };

  // Function to calculate and update the days in range
  const updateDaysInRange = () => {
    const start = moment(startDate);
    const end = moment(endDate);
    let formatDaysInRange = formatDate == "day" ? "days" : "months";
    const daysOrMonths = end.diff(start, formatDaysInRange) + 1;
    setDaysInRange(daysOrMonths);
  };

  const handleStartDateChange = (date) => {
    setIsLoading(true);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formattedDate);
    if (moment(formattedDate).isAfter(endDate)) {
      setEndDate(formattedDate);
    }
    setIsLoading(false);
  };

  const handleEndDateChange = (date) => {
    setIsLoading(true);

    const formattedDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formattedDate);
    if (moment(formattedDate).isBefore(startDate)) {
      setStartDate(formattedDate);
    }
    setIsLoading(false);
  };

  const fetchReportOperator = async () => {
    try {
      setIsLoading(true);
      const respdata = await reportSvc.getReportOperator({
        params: {
          start_date: startDate,
          end_date: endDate,
          range: formatDate,
          id_pelanggan: selectedCustomer.value
            ? selectedCustomer.value
            : selectedCustomer,
          query: searchTerm,
        },
      });
      if (respdata.status === 200) {
        setReportExport(respdata.data.data);
      }

      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    updateDaysInRange();
  }, [startDate, endDate, formatDate]);

  useEffect(() => {
    fetchReportOperator();
  }, [startDate, endDate, selectedCustomer]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      let timerId;
      const fetchData = () => {
        fetchReportOperator();
      };
      const delayedFetch = () => {
        clearTimeout(timerId);
        timerId = setTimeout(fetchData, 500);
      };
      delayedFetch();
      return () => {
        clearTimeout(timerId);
      };
    } else {
      isFirstRender.current = false;
    }
  }, [searchTerm]);

  const handleSelectCustomer = (selectedOption) => {
    setSelectedCustomer(selectedOption);
  };

  const generateDateLabels = (startDate, daysInRange) => {
    const labels = [];
    for (let i = 0; i < daysInRange; i++) {
      if (formatDate === "day") {
        const date = moment(startDate).add(i, "days");
        labels.push(date.format("D MMM"));
      } else if (formatDate === "month") {
        const date = moment(startDate).add(i, "months");
        labels.push(date.format("MMM"));
      }
    }
    return labels;
  };

  const handleTabChange = () => {
    useHistory.push("/");
  };

  const handleFormatChange = (newFormat) => {
    setStartDate(
      moment()
        .subtract(5, newFormat == "day" ? "days" : "months")
        .format(newFormat == "day" ? "YYYY-MM-DD" : "YYYY-MM")
    );
    setEndDate(moment().format(newFormat == "day" ? "YYYY-MM-DD" : "YYYY-MM"));
    setFormatDate(newFormat);
  };

  return (
    <>
      <div className="main-content-page">
        <DashboardInfografisLaporan></DashboardInfografisLaporan>
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <NavTabs
              activeTab={"/operator-summary"}
              onTabChange={handleTabChange}
              sideTab={"infografis"}
            />
            <div className="mt-3 row align-items-center">
              <h5 className="col mb-0">Total Laporan Operator</h5>
              <div className="col-auto">
                <button
                  style={{
                    border: "1px solid #f76617",
                    backgroundColor:
                      formatDate === "day" ? "#f76617" : "transparent",
                    color: formatDate === "day" ? "#ffffff" : "#f76617",
                    borderRadius: "4px",
                    marginRight: "10px",
                  }}
                  onClick={() => handleFormatChange("day")}
                >
                  Perhari
                </button>
                <button
                  style={{
                    border: "1px solid #f76617",
                    backgroundColor:
                      formatDate === "month" ? "#f76617" : "transparent",
                    color: formatDate === "month" ? "#ffffff" : "#f76617",
                    borderRadius: "4px",
                  }}
                  onClick={() => handleFormatChange("month")}
                >
                  Perbulan
                </button>
              </div>
            </div>

            <Row className="align-items-center mt-3">
              <Form>
                <div className="mb-2">
                  <Form.Group id="range">
                    <Form.Label className="text-primary fs-6">
                      Pilih Tanggal
                    </Form.Label>
                    <Row style={{ width: "100%" }}>
                      <Col>
                        <Datetime
                          timeFormat={false}
                          dateFormat="MM-DD"
                          style={{ height: "1px" }}
                          onChange={handleStartDateChange}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={
                                  startDate
                                    ? moment(startDate).format("YYYY-MM-DD")
                                    : ""
                                }
                                placeholder="Tanggal Awal"
                                onFocus={openCalendar}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          )}
                        />
                      </Col>
                      <Col>
                        <Datetime
                          timeFormat={false}
                          dateFormat="MM-DD"
                          style={{ height: "1px" }}
                          onChange={handleEndDateChange}
                          renderInput={(props, openCalendar) => (
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                required
                                type="text"
                                value={
                                  endDate
                                    ? moment(endDate).format("YYYY-MM-DD")
                                    : ""
                                }
                                placeholder="Tanggal Akhir"
                                onFocus={openCalendar}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          )}
                        />
                      </Col>
                      <Col>
                        <FormControl
                          ref={searchInputRef}
                          type="text"
                          placeholder="Cari Operator / Email"
                          className="me-1"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </Col>
                      {user.role == "admin" && (
                        <Col>
                          <InputGroup>
                            <Select
                              className="me-1"
                              id="state"
                              placeholder="Semua Kantor Cabang"
                              styles={{
                                ...customStyles,
                                input: (provided) => ({
                                  ...provided,
                                  minHeight: "36px",
                                }),
                              }}
                              value={selectedCustomer}
                              onChange={handleSelectCustomer}
                              options={customers.map((user) => ({
                                value: user.id_pelanggan,
                                label: user.name,
                              }))}
                            />
                          </InputGroup>
                        </Col>
                      )}
                    </Row>
                  </Form.Group>
                </div>
              </Form>
            </Row>
          </Card.Header>

          <Card.Body
            style={{
              // overflowX: "auto",
              display: "visible",
              overflowX: "scroll",
              overflowY: "scroll",
            }}
          >
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px" }}
              >
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Table
                id="export-table"
                style={{
                  minWidth: "100%",
                  borderCollapse: "collapse",
                  border: "1px solid #ccc",
                }}
              >
                <colgroup>
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  <col style={{ borderRight: "1px solid #ccc" }} />
                  {Array.from({ length: daysInRange }, (_, index) => (
                    <col
                      key={index}
                      style={{ borderRight: "1px solid #ccc" }}
                    />
                  ))}
                </colgroup>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      colSpan={5}
                    >
                      INFORMASI OPERATOR
                    </th>
                    <th
                      style={{
                        padding: "6px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        borderRight: "1px solid #ccc",
                      }}
                      colSpan={daysInRange * 3}
                    >
                      RENTANG TANGGAL {startDate} - {endDate}
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={1}
                    >
                      No
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={1}
                    >
                      NAMA OPERATOR
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={1}
                    >
                      WILAYAH KC
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={1}
                    >
                      PHONE
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        textAlign: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #ccc",
                        verticalAlign: "middle",
                      }}
                      rowSpan={1}
                    >
                      EMAIL
                    </th>
                    {generateDateLabels(startDate, daysInRange).map(
                      (label, index) => (
                        <th
                          style={{
                            padding: "6px",
                            textAlign: "center",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                          key={index}
                          colSpan={1}
                        >
                          {label}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {reportExports.map((item, index) => {
                    const { user, laporans } = item;
                    const { name, pelanggan, email, phone } = user;

                    return (
                      <tr key={index}>
                        {/* Asset details */}
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            backgroundColor: !user?.active
                              ? "#ffff00"
                              : "initial",
                          }}
                        >
                          {name}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            backgroundColor: !user?.active
                              ? "#ffff00"
                              : "initial",
                          }}
                        >
                          {pelanggan?.name}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            backgroundColor: !user?.active
                              ? "#ffff00"
                              : "initial",
                          }}
                        >
                          {phone}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ccc",
                            borderRight: "1px solid #ccc",
                            backgroundColor: !user?.active
                              ? "#ffff00"
                              : "initial",
                          }}
                        >
                          {email}
                        </td>

                        {/* Loop through each day in the range */}
                        {Array.from({ length: daysInRange }, (_, dayIndex) => {
                          const date = moment(startDate).add(
                            dayIndex,
                            formatDate == "day" ? "days" : "months"
                          );
                          const dateString = date.format(
                            formatDate == "day" ? "YYYY-MM-DD" : "YYYY-MM"
                          );
                          const dataLaporan = laporans[dateString] || [];

                          // Render cells for each kunjungan in a day
                          const cells = Array.from(
                            { length: 1 },
                            (_, laporanIndex) => {
                              let backgroundColor = "#FF0000"; // Default background color when no data is available
                              if (dataLaporan && dataLaporan !== undefined) {
                                backgroundColor =
                                  dataLaporan == 0 ? "#FF0000" : "#92D050";
                              }
                              return (
                                <td
                                  key={laporanIndex}
                                  style={{
                                    padding: "8px",
                                    backgroundColor,
                                    color: "#000000",
                                    fontSize: "10px",
                                    border: " solid #ccc",
                                    textAlign: "center",
                                  }}
                                >
                                  {dataLaporan ? (
                                    <a
                                      href="/report"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        color: "#000000",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {dataLaporan}
                                    </a>
                                  ) : (
                                    0
                                  )}
                                </td>
                              );
                            }
                          );

                          return cells;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
