import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { Table } from "@themesberg/react-bootstrap";
import { Skeleton, Button, Card } from "antd";
import {
  Download,
  RefreshCw,
  FileText,
  Home,
  Users,
  PieChart,
} from "lucide-react";
import { getLoginData } from "../services/authSvc";
import reportSvc from "../services/reportSvc";

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [customers, setCustomers] = useState([]);
  const history = useHistory();

  const { user } = getLoginData();

  const handleTabChange = (path) => {
    history.push(path);
  };

  // Fetch data from the backend
  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const resp = await reportSvc.getAssetSummaryReport({
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      if (resp.status === 200) {
        setCustomers(resp.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const totalBranches = customers.length;
  const totalBelumDikerjakan = customers.reduce(
    (acc, c) => acc + (c.belum_dikerjakan || 0),
    0
  );
  const totalAtmAktif = customers.reduce(
    (acc, c) => acc + (c.total_atm_active || 0),
    0
  );
  const branchesWithIssues = customers.filter(
    (c) => (c.belum_dikerjakan || 0) > 0
  ).length;

  // Example: percentage of branches with no pending issues
  const completionRate =
    totalBranches === 0
      ? 0
      : Math.round(
          ((totalBranches - branchesWithIssues) / totalBranches) * 100
        );
  return (
    <div
      style={{
        margin: "0 auto",
        paddingBottom: "1rem",
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      }}
    >
      {/* Top Section */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
          marginBottom: "1rem",
          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        <div style={{ minWidth: "240px" }}>
          <h1 style={{ margin: 0, fontSize: "24px", fontWeight: "bold" }}>
            Dashboard Monitoring
          </h1>
          <p style={{ margin: 0, color: "#666" }}>
            Pantau status ATM dan laporan dari seluruh cabang
          </p>
        </div>
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            style={{ display: "flex", alignItems: "center", gap: "6px" }}
            size="middle"
            icon={<Download size={16} />}
            onClick={() => alert("Export clicked!")}
          >
            Export
          </Button>
          <Button
            style={{ display: "flex", alignItems: "center", gap: "6px" }}
            size="middle"
            icon={<RefreshCw size={16} />}
            onClick={fetchCustomers}
          >
            Refresh Data
          </Button>
        </div>
      </div>

      {/* Summary Cards */}
      {isLoading ? (
        // Show skeletons while loading
        <div
          style={{
            display: "grid",
            gridGap: "16px",
            gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
          }}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        // Show actual data once loaded
        <div
          style={{
            // display: "grid",
            // gridGap: "16px",
            gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
          }}
          className="d-flex flex-wrap justify-content-between gap-3"
        >
          {/* Card 1: Total Laporan Belum Dikerjakan */}
          <Card
            style={{
              border: "1px solid #e8e8e8",
              borderRadius: 8,
              padding: "16px",
              flexGrow: 1,
            }}
            bodyStyle={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                Total Laporan Belum Dikerjakan
              </span>
              <FileText size={18} color="#666" />
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {totalBelumDikerjakan}
            </div>
            <p style={{ margin: 0, fontSize: 12, color: "#888" }}>
              Dari {totalBranches} cabang
            </p>
          </Card>

          {/* Card 2: Total ATM Aktif */}
          <Card
            style={{
              border: "1px solid #e8e8e8",
              borderRadius: 8,
              padding: "16px",
              flexGrow: 1,
            }}
            bodyStyle={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                Total ATM Aktif
              </span>
              <Home size={18} color="#666" />
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {totalAtmAktif}
            </div>
            <p style={{ margin: 0, fontSize: 12, color: "#888" }}>
              Tersebar di {totalBranches} cabang
            </p>
          </Card>

          {/* Card 3: Cabang Dengan Masalah */}
          <Card
            style={{
              border: "1px solid #e8e8e8",
              borderRadius: 8,
              padding: "16px",
              flexGrow: 1,
            }}
            bodyStyle={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                Cabang Dengan Masalah
              </span>
              <Users size={18} color="#666" />
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {branchesWithIssues}
            </div>
            <p style={{ margin: 0, fontSize: 12, color: "#888" }}>
              Memerlukan tindak lanjut
            </p>
          </Card>

          {/* Card 4: Tingkat Penyelesaian */}
          <Card
            style={{
              border: "1px solid #e8e8e8",
              borderRadius: 8,
              padding: "16px",
              flexGrow: 1,
            }}
            bodyStyle={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 0",
              }}
            >
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                Tingkat Penyelesaian
              </span>
              <PieChart size={18} color="#666" />
            </div>
            <div style={{ fontSize: 24, fontWeight: "bold" }}>
              {completionRate}%
            </div>
            <div
              style={{
                height: 8,
                width: "100%",
                backgroundColor: "#f0f0f0",
                borderRadius: 4,
                margin: "8px 0",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${completionRate}%`,
                  backgroundColor: "green",
                  borderRadius: 4,
                }}
              />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
