import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useHistory } from "react-router-dom";

const MapComponent = ({ assets }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const history = useHistory();
  const popupLinkHandler = useRef(null);
  let markerClusterGroup = useRef(null);
  let markersLayer = useRef(null);

  const handleDetailAsset = (id) => {
    history.push(`/asset/detail/${id}`);
  };

  useEffect(() => {
    if (mapContainerRef.current && mapRef.current === null) {
      mapRef.current = L.map(mapContainerRef.current).setView(
        [-6.1259391, 106.5262299],
        12
      );

      L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        attribution: "© Google",
      }).addTo(mapRef.current);

      markerClusterGroup.current = L.markerClusterGroup({
        showCoverageOnHover: false, // Disable hover effect
        disableClusteringAtZoom: 15, // Markers will be separate after zoom level 15
        maxClusterRadius: 50, // Controls the clustering distance
        spiderfyOnMaxZoom: true, // Expands markers at max zoom
        animateAddingMarkers: true, // Smooth animation when adding markers
        removeOutsideVisibleBounds: true, // Remove markers outside the map bounds
      });

      markersLayer.current = L.layerGroup();

      const defaultIcon = L.divIcon({
        className: "custom-marker-icon",
        html: `<div style="background-color: #f76617; width: 10px; height: 10px; border-radius: 50%; border: 1px solid black;"></div>`,
        iconSize: [16, 16],
        iconAnchor: [8, 8],
      });

      const nonActiveIcon = L.divIcon({
        className: "custom-marker-icon",
        html: `<div style="background-color: #ffea00; width: 10px; height: 10px; border-radius: 50%; border: 1px solid black;"></div>`,
        iconSize: [16, 16],
        iconAnchor: [8, 8],
      });

      if (assets) {
        assets.forEach((element) => {
          if (element.latitude && element.longitude) {
            const marker = L.marker([element.latitude, element.longitude], {
              icon: element.active ? defaultIcon : nonActiveIcon,
            });

            const popupContent = `
              <div>
                ${element.name} - ${element.kode} <br />
                ${element?.pelanggan?.name} <br />
                <a href="#" class="popup-link" data-id="${element.id_asset}">
                  Lihat ATM
                </a>
              </div>
            `;

            marker.bindPopup(popupContent);
            markerClusterGroup.current.addLayer(marker);
            mapRef.current.removeLayer(markerClusterGroup.current);
            markersLayer.current.addLayer(marker);
          }
        });
      }

      mapRef.current.addLayer(markerClusterGroup.current);

      // Add button to toggle cluster
      const clusterControl = L.control({ position: "topleft" });

      clusterControl.onAdd = function () {
        const div = L.DomUtil.create("div", "leaflet-control leaflet-bar");
        div.innerHTML = `<button id="toggle-cluster" style="background:white; border:none; padding:5px; cursor:pointer;">🗺️</button>`;
        div.onclick = () => {
          if (mapRef.current.hasLayer(markerClusterGroup.current)) {
            mapRef.current.removeLayer(markerClusterGroup.current);
            mapRef.current.addLayer(markersLayer.current);
          } else {
            mapRef.current.removeLayer(markersLayer.current);
            mapRef.current.addLayer(markerClusterGroup.current);
          }
        };
        return div;
      };

      clusterControl.addTo(mapRef.current);

      // Adding a legend to the map
      const legend = L.control({ position: "bottomright" });

      legend.onAdd = function (map) {
        const div = L.DomUtil.create("div", "info legend");
        const grades = ["Aktif", "Nonaktif"];
        const colors = ["#f76617", "#ffea00"];

        for (let i = 0; i < grades.length; i++) {
          div.innerHTML +=
            `<div style="display: flex; align-items: center; margin-bottom: 5px;">` +
            `<div style="background:${colors[i]}; width: 20px; height: 20px; border-radius: 50%; margin-right: 5px;"></div>` +
            `<div style='font-weight:bold'>${grades[i]}</div>`;
        }

        return div;
      };

      legend.addTo(mapRef.current);

      if (markerClusterGroup.current.getBounds().isValid()) {
        mapRef.current.fitBounds(markerClusterGroup.current.getBounds());
      }
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [assets]);

  return (
    <div
      id="map"
      ref={mapContainerRef}
      style={{
        height: "100%",
        width: "100%",
        zIndex: 1,
      }}
    ></div>
  );
};

export default MapComponent;
