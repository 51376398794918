import {
  // Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { showErrorAlert } from "../../utils/SwalUtil";
import userSvc from "../../services/userSvc";
import { useHistory, useParams } from "react-router-dom";
import { getLoginData } from "../../services/authSvc";
import { Button, Descriptions, Tooltip, Card, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import SpinnerLoading from "../../components/loading/SpinnerLoading";
import reportSvc from "../../services/reportSvc";
import { ColumnLaporan } from "../../template/TemplateColumn";

const DetailUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const { role } = getLoginData().user;
  const [user, setUser] = useState([]);
  const [report, setReport] = useState([]);
  moment.tz.setDefault("Asia/Bangkok");
  const fetchDetailUser = async () => {
    setIsLoading(true);
    try {
      const resp = await userSvc.getUserById(id);

      if (resp.status === 200) {
        setUser(resp.data.data);
      }
    } catch (error) {
      showErrorAlert("Gagal", "Gagal mengambil data user");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchReports = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: 0,
        limit: 100,
        start_date: moment().subtract(6, "months").format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        id_user: id,
      };

      const resp = await reportSvc.getReports({
        params: { ...params },
      });
      if (resp.status === 200) {
        setReport(resp.data.data);
      }
    } catch (error) {
      let message =
        error.response?.data?.message || "Gagal mengambil data report";
      showErrorAlert("Gagal", message);
    } finally {
      setIsLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: "Nama Pengguna",
      children: user.name ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {user.photo_url ? (
            <img
              src={user.photo_url}
              alt="profile-pict"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
          ) : (
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "orange",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}
              >
                {user.name ? user.name.charAt(0) : ""}
              </span>
            </div>
          )}
          <span>{user.name || "-"}</span>
        </div>
      ) : (
        "-"
      ),
    },
    {
      key: "2",
      label: "Email",
      children: user.email || "-",
    },
    {
      key: "3",
      label: "No. Telepon",
      children: user.phone || "-",
    },
    {
      key: "4",
      label: "Role",
      children: user.role || "-",
    },
    {
      key: "5",
      label: "Status",
      children: user.active ? "Aktif" : "Nonaktif",
    },
    {
      key: "6",
      label: "Last Login",
      children: user.last_login
        ? moment(user.last_login).format("YYYY-MM-DD HH:mm:ss")
        : "-",
    },
    {
      key: "7",
      label: "Last IP",
      children: user.last_ip || "-",
    },
    {
      key: "8",
      label: "Tanggal Dibuat",
      children: user.createdAt
        ? moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "-",
    },
    {
      key: "9",
      label: "Tanggal Diperbarui",
      children: user.updatedAt
        ? moment(user.updatedAt).format("YYYY-MM-DD HH:mm:ss")
        : "-",
    },
    {
      key: "10",
      label: "Pelanggan",
      children: user.pelanggan ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {user.pelanggan.photo_url ? (
            <img
              src={user.pelanggan.photo_url}
              alt="profile-pict"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
          ) : (
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "orange",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "10px",
              }}
            >
              <span
                style={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}
              >
                {user.pelanggan.name ? user.pelanggan.name.charAt(0) : ""}
              </span>
            </div>
          )}
          <span>{user.pelanggan.name || "-"}</span>
        </div>
      ) : (
        "-"
      ),
    },
    {
      key: "11",
      label: "EMR V2",
      children:
        user.emrv2s && user.emrv2s.length > 0 ? (
          <div>
            {user.emrv2s.map((emr) => (
              <div key={emr.id_emrv2} style={{ marginBottom: "4px" }}>
                <div style={{ fontWeight: "bold" }}>Token:</div>
                <div>{emr.token}</div>
                <div>
                  {moment(emr.start_date).format("YYYY-MM-DD")} to{" "}
                  {moment(emr.end_date).format("YYYY-MM-DD")}
                </div>
              </div>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      key: "12",
      label: "User Asset",
      children: user.user_asset ? (
        <div>
          <div>
            <strong>Keterangan:</strong> {user.user_asset.keterangan || "-"}
          </div>
          <div>
            <strong>Kantor Cabang:</strong>{" "}
            {user.user_asset.pelanggan1 ? user.user_asset.pelanggan1.name : "-"}
            {user.user_asset.pelanggan2
              ? `, ${user.user_asset.pelanggan2.name}`
              : ""}
          </div>
        </div>
      ) : (
        "-"
      ),
    },
  ];

  const goToDetailLaporan = (id) => {
    history.push(`/report-detail/${id}`);
  };

  const goToEditUser = async (id) => {
    history.push(`/user/update/${id}`);
  };

  const columnLaporan = ColumnLaporan(goToDetailLaporan);
  const paginationLaporan = {
    showTotal: (total, range) =>
      `Showing ${range[0]}-${range[1]} of ${total} items`,
    pageSize: 5,
    total: report.length,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    className: "custom-pagination",
  };

  // useEffect(() => {
  //   const getDetail = async () => {
  //     await fetchDetailUser();
  //   };

  //   getDetail();
  // }, []);

  useEffect(() => {
    fetchDetailUser();
    fetchReports();
  }, [id]);

  return (
    <div className="main-content-page d-flex flex-column gap-2">
      <Card
        title={
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Detail Pengguna</span>
          </span>
        }
        extra={
          <div className="d-flex">
            {role === "admin" && (
              <Tooltip title={"Edit Pengguna"}>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => {
                    goToEditUser(id);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPen}
                    style={{ fontSize: "14px", paddingRight: "10px" }}
                  />
                  Edit
                </Button>
              </Tooltip>
            )}
          </div>
        }
        style={{ width: "100%" }}
      >
        {isLoading ? (
          <SpinnerLoading height="h-40" />
        ) : (
          <Descriptions layout="vertical" items={items} />
        )}
      </Card>

      <Card
        title={
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>100 Laporan Terakhir</span>
          </span>
        }
        extra={<div className="d-flex"></div>}
        style={{ width: "100%" }}
      >
        <Table
          columns={columnLaporan}
          dataSource={report}
          pagination={paginationLaporan}
          loading={isLoading}
          size="middle"
          style={{ overflow: "auto" }}
        ></Table>
      </Card>

      {/* <Card border="light" className="shadow-sm">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Detail User</h5>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : user.name ? (
            <Container>
              <Row>
                <Col>
                  <div className="mb-4">
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="mb-2"
                    >
                      {user.photo_url ? (
                        <Image
                          src={`${user.photo_url}`}
                          alt="profile-pict"
                          width="40px"
                          height="40px"
                          style={{ borderRadius: "50%", marginRight: "10px" }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: "orange",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {user.name ? user.name.charAt(0) : ""}
                          </span>
                        </div>
                      )}
                      <div>
                        <span className="fw-bold">{user.name}</span>
                      </div>
                    </div>

                    <div>
                      <span className="text-muted text-muted">Role</span>
                      <br />
                      <p className="h6">{user.role}</p>
                    </div>
                    <div>
                      <span className="text-muted">Kontak</span>
                      <br />
                      <p className="h6">{user.phone}</p>
                    </div>
                    <div>
                      <span className="text-muted">Email</span>
                      <br />
                      <p className="h6">{user.email}</p>
                    </div>

                    <div>
                      <span className="text-muted">Status</span>
                      <br />
                      <div>
                        {user.active ? (
                          <p className="text-success fw-bolder">Aktif</p>
                        ) : (
                          <p className="text-danger fw-bolder">Nonaktif</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                {user.pelanggan ? (
                  <Col>
                    <div>
                      <h5>Kepemilikan</h5>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="mb-2"
                      >
                        {user.pelanggan.photo_url ? (
                          <Image
                            src={`${user.pelanggan.photo_url}`}
                            alt="profile-pict"
                            width="40px"
                            height="40px"
                            style={{ borderRadius: "50%", marginRight: "10px" }}
                          />
                        ) : (
                          <div
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              backgroundColor: "orange",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "10px",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {user.pelanggan.name
                                ? user.pelanggan.name.charAt(0)
                                : ""}
                            </span>
                          </div>
                        )}
                        <div>
                          <span className="fw-bold">{user.pelanggan.name}</span>
                        </div>
                      </div>
                      <div>
                        <span className=" text-muted ">Alamat</span>
                        <br />
                        <p className="h6">{user.pelanggan.alamat}</p>
                      </div>
                      <div>
                        <span className=" text-muted ">Kontak</span>
                        <br />
                        <p className="h6">{user.pelanggan.phone}</p>
                      </div>
                      <div>
                        <span className=" text-muted ">Email</span>
                        <br />
                        <p className="h6">{user.pelanggan.email}</p>
                      </div>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <hr />
              <div>
                <small className="text-muted text-s">
                  Created at{" "}
                  {user.createdAt
                    ? moment.utc(user.createdAt).format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </small>
                <br />
                <small className="text-muted text-s">
                  Last updated{" "}
                  {user.updatedAt
                    ? moment.utc(user.updatedAt).format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </small>
              </div>
            </Container>
          ) : (
            <div className="text-center">
              Failed to fetch asset. Please try again later.
            </div>
          )}
        </Card.Body>
      </Card> */}
    </div>
  );
};

export default DetailUser;
