import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "@themesberg/react-bootstrap";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload, message } from "antd";
import customerSvc from "../../services/customerSvc";
import { useParams, useHistory } from "react-router";
import { showErrorAlert, showSuccessAlert } from "../../utils/SwalUtil";
import reportTransactionSvc from "../../services/reportTransactionSvc";
import { getLoginData } from "../../services/authSvc";
import assetSvc from "../../services/assetSvc";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
moment().tz("Asia/Jakarta");

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(null);
  const [id_asset, setIdAsset] = useState(null);
  const [id_pelanggan, setIdPelanggan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [beforeImageList, setBeforeImageList] = useState([]);
  const [afterImageList, setAfterImageList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [customDate, setCustomDate] = useState(moment().format("YYYY-MM-DD"));
  const [customTime, setCustomTime] = useState(moment().format("HH:mm"));
  const history = useHistory();
  const { user } = getLoginData();
  const { id } = useParams();

  useEffect(() => {
    fetchCustomers();
    if (id) {
      fetchReportTransactionById();
    }
  }, []);

  useEffect(() => {
    setAssets([]);
    fetchAssets();
  }, [id_pelanggan]);

  const formatFileList = (images) => {
    return images.map((img) => ({
      uid: img.uid,
      name: img.image_url.split("/").pop(), // Extract filename from URL
      status: "done", // Status indicating the file is already uploaded
      url: img.image_url, // URL of the image
      image_type: img.image_type,
      id: img.id_report_transaction_image,
    }));
  };

  const fetchReportTransactionById = async () => {
    try {
      setIsLoading(true);
      const resp = await reportTransactionSvc.getReportTransactionById(id);
      if (resp.status === 200) {
        setName(resp.data.data.name);
        setDescription(resp.data.data.description);
        setPrice(resp.data.data.price);
        setIdAsset(resp.data.data.asset.id_asset);
        setIdPelanggan(resp.data.data.pelanggan.id_pelanggan);
        const beforeImages = resp.data.data.report_transaction_images.filter(
          (img) => img.image_type === "before"
        );
        const afterImages = resp.data.data.report_transaction_images.filter(
          (img) => img.image_type === "after"
        );
        const beforeFileList = formatFileList(beforeImages);
        const afterFileList = formatFileList(afterImages);
        setBeforeImageList(beforeFileList);
        setAfterImageList(afterFileList);
        setCustomDate(
          moment(resp.data.data.operation_date).format("YYYY-MM-DD")
        );
        setCustomTime(moment(resp.data.data.operation_date).format("HH:mm:ss"));
      }
    } catch (error) {
      console.log({ error });
      showErrorAlert("Error Fetching", "Gagal mendapatkan data");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const respCust = await customerSvc.getCustomer({
        params: {
          page: 0,
          limit: 1000,
          active: true,
        },
      });

      if (respCust.status === 200) {
        setCustomers(respCust.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAssets = async () => {
    try {
      const respCust = await assetSvc.getAsset({
        params: {
          page: 0,
          limit: 1000,
          active: true,
          id_pelanggan: id_pelanggan,
        },
      });

      if (respCust.status === 200) {
        setAssets(respCust.data.data);
      }
    } catch (error) {
    } finally {
    }
  };

  const handleSave = async () => {
    try {
      if (!name || !description || !id_pelanggan || !id_asset) {
        showErrorAlert("Gagal", "Kolom harus diisi lengkap");
        return;
      }

      if (id) {
        // Prepare images data
        const images = [
          ...beforeImageList.map((img) => ({
            id_report_transaction_image: img.id ? img.id : null,
            image_url: img.url ? img.url : img.response.data,
            image_type: "before",
          })),
          ...afterImageList.map((img) => ({
            id_report_transaction_image: img.id ? img.id : null,
            image_url: img.url ? img.url : img.response.data,
            image_type: "after",
          })),
        ];

        const data = {
          name,
          description,
          price,
          id_asset,
          id_pelanggan,
          images,
          date: customDate,
          date_time: customTime,
        };
        const resp = await reportTransactionSvc.updateReportTransaction(
          id,
          data
        );
        if (resp.status === 200) {
          showSuccessAlert("Berhasil", "Data  berhasil diubah");
          history.goBack();
        } else {
          let message = resp?.response?.data?.message || "Gagal mengubah data ";
          showErrorAlert("Gagal", message);
        }
      } else {
        const images = [
          ...beforeImageList.map((img) => ({
            image_url: img.response.data,
            image_type: "before",
          })),
          ...afterImageList.map((img) => ({
            image_url: img.response.data,
            image_type: "after",
          })),
        ];

        const data = {
          name,
          description,
          price,
          id_asset,
          id_pelanggan,
          images, // Include images in the request
          date: customDate,
          date_time: customTime,
        };

        const resp = await reportTransactionSvc.addReportTransaction(data);
        if (resp.status === 200) {
          showSuccessAlert("Berhasil", "Transaksi berhasil ditambahkan");
          history.goBack();
        } else {
          let message =
            resp?.response?.data?.message || "data tidak berhasil ditambahkan";
          showErrorAlert("Gagal", message);
        }
      }
    } catch (error) {
      console.log({ error });
      let message =
        error.response?.data?.message || "Gagal menambah/mengubah data ";
      showErrorAlert("Gagal", message);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChangeBefore = ({ fileList: newFileList }) => {
    console.log("before ", { newFileList });
    setBeforeImageList(newFileList);
  };

  const handleChangeAfter = ({ fileList: newFileList }) => {
    console.log("after ", { newFileList });
    setAfterImageList(newFileList);
  };

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await reportTransactionSvc.uploadFileService(formData);
      // Process the response as needed
      if (response.status !== 200) {
        throw new Error(response.message);
      }
      onSuccess(response.data, file);
      message.success("File uploaded successfully").then(() => {});
    } catch (error) {
      onError(error);
      message.error("File upload failed");
    }
  };

  const handleCustomDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setCustomDate(formattedDate);
  };

  const handleCustomTimeChange = (time) => {
    setCustomTime(time.format("HH:mm"));
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <div className="main-content-page">
      <Card>
        <Card.Header>
          <Card.Title className="h6">
            {id ? "Edit Transaksi" : "Tambah Transaksi"}
          </Card.Title>
        </Card.Header>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "300px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Nama Kegiatan</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan Nama Kegiatan"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Deskripsi</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Masukkan deskripsi kegiatan"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Harga</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Masukkan Harga (optional)"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              {user && user.role === "admin" && (
                <Form.Group className="mb-3" controlId="customer">
                  <Form.Label>Cabang</Form.Label>
                  <Form.Select
                    value={id_pelanggan === null ? "" : id_pelanggan}
                    placeholder="Pilih Cabang"
                    onChange={(e) =>
                      setIdPelanggan(
                        e.target.value === "" ? null : e.target.value
                      )
                    }
                  >
                    <option value="" disabled>
                      Pilih Cabang
                    </option>
                    {customers &&
                      customers.map((customer) => (
                        <option
                          key={customer.id_pelanggan}
                          value={customer.id_pelanggan}
                        >
                          {customer.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              )}

              {user && user.role === "admin" && (
                <Form.Group className="mb-3" controlId="asset">
                  <Form.Label>ATM</Form.Label>
                  <Form.Select
                    value={id_asset}
                    placeholder="Pilih ATM"
                    onChange={(e) =>
                      setIdAsset(e.target.value === "" ? null : e.target.value)
                    }
                  >
                    <option key={null} value="">
                      Pilih Cabang terlebih dahulu
                    </option>
                    {assets &&
                      assets.map((data) => (
                        <option key={data.id_asset} value={data.id_asset}>
                          {data.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              )}
              {user && user.role === "admin" && (
                <Form.Group className="mb-3" controlId="asset">
                  <Form.Label>Custom Tanggal Pengerjaan (Opsional)</Form.Label>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <Datetime
                          timeFormat={false}
                          onChange={handleCustomDateChange}
                          renderInput={(props, openCalendar) => (
                            <InputGroup className="me-2">
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </InputGroup.Text>
                              <Form.Control
                                type="text"
                                value={
                                  customDate
                                    ? moment(customDate).format("YYYY-MM-DD")
                                    : ""
                                }
                                placeholder="YYYY-MM-DD"
                                onFocus={openCalendar}
                                onChange={() => {}}
                              />
                            </InputGroup>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <Datetime
                        dateFormat={false}
                        timeFormat="HH:mm"
                        defaultValue={customTime}
                        onChange={handleCustomTimeChange}
                        renderInput={(props, openCalendar) => (
                          <InputGroup className="me-2">
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faClock} />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              value={
                                customTime
                                  ? moment(customTime, "HH:mm").format("HH:mm")
                                  : ""
                              }
                              placeholder="HH:mm"
                              onFocus={openCalendar}
                              onChange={() => {}}
                            />
                          </InputGroup>
                        )}
                      />
                    </div>
                  </div>
                </Form.Group>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Upload Foto Sebelum</Form.Label>
                <Upload
                  customRequest={handleUpload}
                  listType="picture-card"
                  fileList={beforeImageList}
                  onPreview={handlePreview}
                  onChange={handleChangeBefore}
                  maxCount={2}
                  accept="image/*"
                  capture="environment"
                  type="select"
                >
                  {beforeImageList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Upload Foto Sesudah</Form.Label>
                <Upload
                  customRequest={handleUpload}
                  listType="picture-card"
                  fileList={afterImageList}
                  onPreview={handlePreview}
                  onChange={handleChangeAfter}
                  maxCount={2}
                  accept="image/*"
                  capture="environment"
                  type="select"
                >
                  {afterImageList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Group>

              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: "none",
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
            </Form>
          </Card.Body>
        )}

        <Card.Footer>
          <Button
            className="mx-2"
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Kembali
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Simpan Perubahan
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
};
