import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
  Container,
  Alert,
} from "@themesberg/react-bootstrap";
import { Image } from "antd";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import GmeLogo from "../assets/img/icons/gme-icon.svg";
import docTypeSvc from "../services/docTypeSvc";
import Emr2Svc from "../services/Emr2Svc";
import Select from "react-select";
import imageCompression from "browser-image-compression";
import moment from "moment";
import {
  showErrorAlert,
  showLoadingAlert,
  showSuccessAlert,
} from "../utils/SwalUtil";
import { getLoginData } from "../services/authSvc";
import Datetime from "react-datetime";
import {
  faCalendarAlt,
  faClock,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import assetSvc from "../services/assetSvc";
import { api, apiClient } from "../services/api";
import watermark from "watermarkjs";

export default () => {
  const [isValid, setIsValid] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [temuanFiles, setTemuanFiles] = useState([]);
  const [isTemuan, setIsTemuan] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [keterangan, setKeterangan] = useState("");
  const [asset, setAsset] = useState([]);
  const [users, setUsers] = useState([]);
  const [idUser, setIdUser] = useState("");
  const [idAsset, setIdAsset] = useState(null);
  const [docType, setDocType] = useState([]);
  const [date, setDate] = useState(null);
  const [upload, setUpload] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { token } = useParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedImages, setSelectedImages] = useState({});
  const [selectedTemuans, setSelectedTemuans] = useState({});
  const { id } = useParams();
  const { user } = getLoginData();

  const [customDate, setCustomDate] = useState(moment().format("YYYY-MM-DD"));
  const currentDate = new Date();
  const [customTime, setCustomTime] = useState("");

  const fetchDocType = async () => {
    setIsLoading(true);

    try {
      const resp = await docTypeSvc.getJenisDokumentasiEmr({
        params: {
          active: true,
        },
      });
      if (resp.status === 200) {
        setDocType(resp.data.data);
      }
    } catch (error) {}
  };

  const handleAssetChange = (selectedOption) => {
    setSelectedAsset(selectedOption);
    setIdAsset(selectedOption.value);
  };

  const resizeAndCompressImage = async (file) => {
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "image/jpeg",
    };

    try {
      const compressedBlob = await imageCompression(file, options);
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}_${file.name}`;
      const compressedFile = new File([compressedBlob], uniqueFileName, {
        type: "image/jpeg",
        lastModified: timestamp,
      });
      return compressedFile;
    } catch (error) {
      throw new Error("Gagal melakukan kompresi gambar");
    }
  };

  const addWatermark = async (imageUrl, information = {}) => {
    console.log({ information });
    const time = moment().format("YYYY-MM-DD HH:mm:ss");
    return new Promise((resolve, reject) => {
      watermark([imageUrl])
        .image((image) => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);

          // Set font properties
          const fontSize = 40; // Font size in pixels
          const lineHeight = 1.2 * fontSize; // Line height in pixels (1.2 times font size)
          const fontFamily = "Arial"; // Font family
          const color = "#ffffff"; // Text color

          // Text content for each row
          const textRows = [
            `Operator - ${information?.user?.name}`,
            `EMR V2 - ${information?.nama_jenis_dokumentasi}`,
            `${information?.name} - ${information?.kode}`,
            `${time}`,
            // "dawdawda",
            // "dada ba bd agjdvuavdu68t26t2gbdyb27yib",
            // "dabwhjdba diawgdybawhdbawhbdyiawb dba wdgavwugd awjhdawyud bjaw dutawvdgwa dutwavdha dutawvdgaw dy8awvdgjabw duaw djawd",
            // "daw huda hjdvyawd bawd8qy dihqdyw wahdgtua ",
            // "Row 6",
          ];

          // Set the position of the text
          const startX = canvas.width - 20; // Starting X position (left margin)
          const startY = canvas.height - textRows.length * lineHeight - 5; // Starting Y position (bottom margin)
          const rowSpacing = lineHeight; // Spacing between rows

          // Draw each row of text
          ctx.font = `bold ${fontSize}px ${fontFamily}`;
          ctx.strokeStyle = "black"; // Stroke color
          ctx.lineWidth = 0.5; // Stroke width
          ctx.fillStyle = color; // Text color
          ctx.shadowColor = "rgba(0, 0, 0, 0.5)"; // Shadow color
          textRows.forEach((text, index) => {
            const x = startX - ctx.measureText(text).width; // X position (left margin)
            const y = startY + index * rowSpacing; // Y position
            ctx.fillText(text, x, y); // Fill text
            ctx.strokeText(text, x, y); // Stroke text
          });

          return canvas;
        })
        .then((img) => {
          fetch(img.src)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], "wm_image.jpg", {
                type: "image/jpeg",
                lastModified: Date.now(),
              });
              resolve(file);
            })
            .catch((error) => {
              console.error("Error fetching watermarked image:", error);
              reject(error);
            });
        })
        .catch((error) => {
          console.error("Error during watermarking:", error);
          reject(error);
        });
    });
  };

  const validateToken = async () => {
    setIsLoading(true);
    const data = {
      token: token,
    };
    try {
      const resp = await Emr2Svc.validateEmrV2(data);
      if (resp.status === 200) {
        fetch(`${apiClient}/api/v1/assetuser/${resp.data.data.id_user}`)
          .then((response) => response.json())
          .then((data) => {
            setAsset(data.data);
          })
          .catch((error) => {
            console.error(error);
          });

        setIdUser(resp.data.data.id_user);
        setUsers(resp.data.data.user);
        setStartDate(resp.data.data.start_date);
        setEndDate(resp.data.data.end_date);
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } catch (error) {}
  };

  const handleReportFileUpload = async (files, index, doc) => {
    try {
      showLoadingAlert("Tunggu", "Sedang mengompresi ukuran gambar...");

      const selectedFile = files[0];

      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = async () => {
          const imageDataUrl = reader.result;
          console.log({ imageDataUrl });
          const imageWatermarked = await addWatermark(imageDataUrl, {
            ...selectedAsset,
            ...doc,
            user,
          });

          setSelectedImages((prevImages) => ({
            ...prevImages,
            [index]: URL.createObjectURL(imageWatermarked),
          }));

          const resizedImage = await resizeAndCompressImage(imageWatermarked);

          console.log({ resizedImage });
          const fileData = {
            [index]: resizedImage,
          };

          setUploadedFiles((prevUploadedFiles) => ({
            ...prevUploadedFiles,
            ...fileData,
          }));
        };
        reader.onerror = () => {
          showErrorAlert("Gagal", "Gagal membaca file gambar");
        };
        reader.readAsDataURL(selectedFile);
      }
    } catch (error) {
      console.log({ error });
      showErrorAlert("Gagal", "Ukuran gambar gagal dikompres");
    } finally {
      Swal.close();
    }
  };

  const handleCustomDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setCustomDate(formattedDate);
  };

  const handleCustomTimeChange = (time) => {
    setCustomTime(time.toDate());
  };

  useEffect(() => {
    // Check if all file inputs are filled
    const isAllFilesFilled = docType.every(
      (doc) => uploadedFiles[doc.id_jenis_dokumentasi]
    );

    // Update the form validation state
    setIsFormValid(isAllFilesFilled);
  }, [uploadedFiles, docType]);

  const handleTemuanFileUpload = async (files, index) => {
    try {
      showLoadingAlert("Tunggu", "Sedang mengompresi ukuran gambar...");

      const selectedFile = files[0];

      if (selectedFile) {
        const reader = new FileReader();
        reader.onload = async () => {
          const imageDataUrl = reader.result;
          const imageWatermarked = await addWatermark(imageDataUrl, {
            ...asset,
            nama_jenis_dokumentasi: `temuan ${index + 1}`,
            user,
          });

          setSelectedTemuans((prevImages) => ({
            ...prevImages,
            [`temuan${index + 1}_url`]: URL.createObjectURL(imageWatermarked),
          }));

          const resizedImage = await resizeAndCompressImage(imageWatermarked);

          const propertyName = `temuan${index + 1}_url`;

          const fileData = {
            [propertyName]: resizedImage,
          };

          setTemuanFiles((prevUploadedFiles) => ({
            ...prevUploadedFiles,
            ...fileData,
          }));
        };
        reader.onerror = () => {
          showErrorAlert("Gagal", "Gagal membaca file gambar");
        };
        reader.readAsDataURL(selectedFile);
      }
    } catch (error) {
      console.log({ error });
      showErrorAlert("Gagal", "Ukuran gambar gagal dikompres");
    } finally {
      Swal.close();
    }
  };

  useEffect(() => {
    fetchDocType();
    validateToken();

    setIsLoading(false);
  }, []);

  const handleSave = async () => {
    // Show SweetAlert confirmation dialog
    const result = await Swal.fire({
      title: "Konfirmasi Upload",
      text: `Apa anda yakin untuk mengupload laporan ${selectedAsset.label}?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
    });

    if (result.isConfirmed) {
      const formData = new FormData();
      for (const key in uploadedFiles) {
        if (uploadedFiles.hasOwnProperty(key)) {
          const fileData = uploadedFiles[key];
          formData.append(key, fileData);
        }
      }

      for (const key in temuanFiles) {
        if (temuanFiles.hasOwnProperty(key)) {
          const fileData = temuanFiles[key];
          formData.append(key, fileData);
        }
      }

      formData.append("token", token);
      formData.append("id_asset", idAsset);
      // formData.append("id_user", idUser);
      formData.append("keterangan", keterangan);
      formData.append("is_temuan", isTemuan);

      if (user && user.role === "admin") {
        formData.append("date", customDate);
        if (customTime !== "") {
          formData.append("date_time", moment(customTime).format("HH:mm:ss"));
        }
      }

      try {
        showLoadingOverlay();
        const resp = await Emr2Svc.addReportEmrV2(formData);
        hideLoadingOverlay();
        if (resp.status === 200) {
          showSuccessAlert(
            "Berhasil",
            `Laporan untuk ${resp?.data?.laporan?.asset_name} berhasil dikirim`
          );
        } else {
          let message =
            resp?.response?.data?.message || "Gagal mengirim laporan";
          showErrorAlert("Gagal", message);
        }
      } catch (error) {
        let message = error.response?.data?.message || "Gagal mengirim laporan";
        showErrorAlert("Gagal", message);
      }
    }
  };

  const showLoadingOverlay = () => {
    const loadingOverlay = document.getElementById("root");
    if (loadingOverlay) {
      loadingOverlay.style.display = "none";
    }
    const load = document.getElementById("loading-overlay");
    if (load) {
      load.style.display = "block";
    }
  };

  const hideLoadingOverlay = () => {
    const loadingOverlay = document.getElementById("root");
    if (loadingOverlay) {
      loadingOverlay.style.display = "block";
    }
    const load = document.getElementById("loading-overlay");
    if (load) {
      load.style.display = "none";
    }
  };

  return (
    <>
      <Container>
        <div className="main-content-page">
          {isValid ? (
            <Card border="light" className="shadow-sm">
              <Card.Header>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <a href="/">
                    <Image
                      src={GmeLogo}
                      alt="profile-pict"
                      preview={false}
                      style={{
                        borderRadius: "50%",
                        marginRight: "8px",
                      }}
                    />
                    <div>
                      <h2>GME - EMR V2 Tools</h2>
                    </div>
                  </a>
                </div>
              </Card.Header>
              <Card.Body className="pb-0">
                {isLoading ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSave();
                    }}
                  >
                    <div className="report-info">
                      <div className="mb-3">
                        <span className="fw-bold">Oleh Operator : </span>
                        <span>{users.name}</span>
                      </div>
                      <div className="mb-3">
                        <span className="fw-bold">Tanggal : </span>
                        <span>{moment().format("YYYY-MM-DD")}</span>
                      </div>
                      {user && user.role === "admin" ? (
                        <>
                          <Form.Label>
                            Custom Tanggal Laporan (Admin)
                          </Form.Label>
                          <div className="row">
                            <div className="col">
                              <div className="mb-3">
                                <Datetime
                                  timeFormat={false}
                                  onChange={handleCustomDateChange}
                                  renderInput={(props, openCalendar) => (
                                    <InputGroup className="me-2">
                                      <InputGroup.Text>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                      </InputGroup.Text>
                                      <Form.Control
                                        type="text"
                                        value={
                                          customDate
                                            ? moment(customDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""
                                        }
                                        placeholder="YYYY-MM-DD"
                                        onFocus={openCalendar}
                                        onChange={() => {}}
                                      />
                                    </InputGroup>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <Datetime
                                dateFormat={false}
                                timeFormat="HH:mm"
                                defaultValue={moment().format("HH:mm")}
                                onChange={handleCustomTimeChange}
                                renderInput={(props, openCalendar) => (
                                  <InputGroup className="me-2">
                                    <InputGroup.Text>
                                      <FontAwesomeIcon icon={faClock} />
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      value={
                                        customTime
                                          ? moment(customTime).format("HH:mm")
                                          : ""
                                      }
                                      placeholder="HH:mm"
                                      onFocus={openCalendar}
                                      onChange={() => {}}
                                    />
                                  </InputGroup>
                                )}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* {isLoadingUserAsset ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "300px" }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <> */}
                    {asset.length > 0 && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>ATM</Form.Label>
                          <Select
                            id="state"
                            required
                            placeholder="Pilih ATM"
                            isDisabled={upload === "true" ? true : false}
                            value={selectedAsset}
                            onChange={handleAssetChange}
                            options={asset.map((ast) => ({
                              value: ast.id_asset,
                              label: `${ast?.name} - ${ast?.kode}`,
                            }))}
                          />
                        </Form.Group>
                      </>
                    )}
                    {selectedAsset?.label && (
                      <Alert variant="warning">
                        <div className="d-flex ">
                          <FontAwesomeIcon
                            icon={faWarning}
                            style={{ color: "white" }}
                            className="p-2 bg-warning rounded "
                          />
                          <p className="ps-2 mb-0">
                            Pastikan ATM <strong>{selectedAsset?.label}</strong>{" "}
                            merupakan ATM yang akan dilaporkan
                          </p>
                        </div>
                      </Alert>
                    )}

                    <Form.Group className="mb-3">
                      <Form.Label>Apakah data sudah benar?</Form.Label>
                      <InputGroup>
                        <Form.Select
                          id="state"
                          defaultValue={true}
                          value={upload}
                          disabled={selectedAsset === null ? true : false}
                          onChange={(e) => {
                            console.log(e.target.value);
                            console.log({ upload });
                            setUpload(e.target.value);
                          }}
                        >
                          <option value={true}>Iya</option>
                          <option value={false}>Tidak</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                    {upload === "true" && (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label>Upload Foto Laporan</Form.Label>
                          <br />
                          {docType.length > 0 ? (
                            docType.map((doc) => (
                              <React.Fragment key={doc.id_jenis_dokumentasi}>
                                <span className="mb-2 mt-1">
                                  {doc.nama_jenis_dokumentasi}
                                </span>
                                <InputGroup className="mt-1 mb-1">
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleReportFileUpload(
                                        e.target.files,
                                        doc.id_jenis_dokumentasi,
                                        doc
                                      )
                                    }
                                    required // Added the required attribute
                                  />
                                </InputGroup>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    maxWidth: "70%",
                                    margin: "10px auto",
                                  }}
                                >
                                  {selectedImages[doc.id_jenis_dokumentasi] && (
                                    <Image
                                      src={
                                        selectedImages[doc.id_jenis_dokumentasi]
                                      }
                                      alt="Selected"
                                      style={{}}
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            ))
                          ) : (
                            <div>
                              <p>Gagal mendapatkan jenis dokumentasi</p>
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Apakah ada temuan?</Form.Label>
                          <InputGroup>
                            <Form.Select
                              id="state"
                              value={isTemuan}
                              onChange={(e) => {
                                setIsTemuan(e.target.value);
                              }}
                            >
                              <option value={true}>Iya</option>
                              <option value={false}>Tidak</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>

                        {isTemuan === "true" && (
                          <>
                            <Form.Group className="mb-3">
                              <Form.Label>Keterangan</Form.Label>
                              <InputGroup>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Enter keterangan"
                                  value={keterangan}
                                  onChange={(e) =>
                                    setKeterangan(e.target.value)
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                Upload Foto Temuan (Max 2)
                              </Form.Label>
                              {Array.from(Array(2).keys()).map((index) => (
                                <div>
                                  <InputGroup key={index} className="mb-2">
                                    <Form.Control
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleTemuanFileUpload(
                                          e.target.files,
                                          index
                                        )
                                      }
                                    />
                                  </InputGroup>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      maxWidth: "70%",
                                      margin: "10px auto",
                                    }}
                                  >
                                    {selectedTemuans[
                                      `temuan${index + 1}_url`
                                    ] && (
                                      <Image
                                        src={
                                          selectedTemuans[
                                            `temuan${index + 1}_url`
                                          ]
                                        }
                                        alt="Selected"
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </Form.Group>
                          </>
                        )}
                      </>
                    )}
                    <Card.Footer className="px-0 d-lg-flex align-items-center justify-content-between">
                      <Button
                        type="submit"
                        variant="primary"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   handleSave();
                        // }}
                        disabled={!isFormValid} // Disable the button if the form is not valid
                      >
                        Kirim
                      </Button>
                    </Card.Footer>
                  </Form>
                )}
              </Card.Body>
            </Card>
          ) : (
            <p>Token anda tidak valid</p>
          )}
        </div>
      </Container>
    </>
  );
};
