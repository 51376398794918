import {
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { showErrorAlert } from "../../utils/SwalUtil";
import customerSvc from "../../services/customerSvc";
import { useParams } from "react-router-dom";

const DetailCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [customer, setCustomer] = useState([]);
  moment.tz.setDefault("Asia/Bangkok");
  const fetchDetailCustomer = async () => {
    setIsLoading(true);
    try {
      const resp = await customerSvc.getCustomerById(id);

      if (resp.status === 200) {
        setCustomer(resp.data.data);
      }
    } catch (error) {
      showErrorAlert("Gagal", "Gagal mengambil data customer");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getDetail = async () => {
      await fetchDetailCustomer();
    };

    getDetail();
  }, []);

  return (
    <div className="main-content-page">
      <Card border="light" className="shadow-sm">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Detail Kantor Cabang</h5>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : customer.name ? (
            <Container>
              <Row>
                <Col>
                  <div className="mb-4">
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="mb-2"
                    >
                      {customer.photo_url ? (
                        <Image
                          src={`${customer.photo_url}`}
                          alt="profile-pict"
                          width="40px"
                          height="40px"
                          style={{ borderRadius: "50%", marginRight: "10px" }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            backgroundColor: "orange",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: "10px",
                          }}
                        >
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {customer.name ? customer.name.charAt(0) : ""}
                          </span>
                        </div>
                      )}
                      <div>
                        <span className="fw-bold">{customer.name}</span>
                      </div>
                    </div>

                    <div>
                      <span className="text-muted">Kontak</span>
                      <br />
                      <p className="h6">{customer.phone}</p>
                    </div>
                    <div>
                      <span className="text-muted">Email</span>
                      <br />
                      <p className="h6">{customer.email}</p>
                    </div>

                    <div>
                      <span className="text-muted">Status</span>
                      <br />
                      <div>
                        {customer.active ? (
                          <p className="text-success fw-bolder">Aktif</p>
                        ) : (
                          <p className="text-danger fw-bolder">Nonaktif</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div>
                    <div>
                      <span className=" text-muted ">Total User</span>
                      <br />
                      <p className="h6">{customer.users.length}</p>
                    </div>
                    <div>
                      <span className=" text-muted ">Total Aset</span>
                      <br />
                      <p className="h6">{customer.assets.length}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr />
              <div>
                <small className="text-muted text-s">
                  Created at{" "}
                  {customer.createdAt
                    ? moment
                        .utc(customer.createdAt)
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </small>
                <br />
                <small className="text-muted text-s">
                  Last updated{" "}
                  {customer.updatedAt
                    ? moment
                        .utc(customer.updatedAt)
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "-"}
                </small>
              </div>
            </Container>
          ) : (
            <div className="text-center">
              Failed to fetch customer. Please try again later.
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default DetailCustomer;
