import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment";
import {
  faHouseMedicalCircleExclamation,
  faMapMarked,
  faPenToSquare,
  faPlus,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Form,
  InputGroup,
  Spinner,
  FormControl,
} from "@themesberg/react-bootstrap";

import { Input, Space } from "antd";

import { Link, useHistory } from "react-router-dom";
import { getLoginData } from "../../services/authSvc";
import NavTabs from "../../components/NavTabs";
import { api, apiClient } from "../../services/api";
import assetSvc from "../../services/assetSvc";
import Emr2Svc from "../../services/Emr2Svc";
import userSvc from "../../services/userSvc";
import { isExpired } from "../../utils/Helper";
moment.tz.setDefault("Asia/Bangkok");

const { Search } = Input;
const { user } = getLoginData();

export default () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef(null);

  const [tokens, SetTokens] = useState([]);
  //   const [filteredAssets, setFilteredAssets] = useState([]);

  const fetchEMR = async () => {
    setIsLoading(true);
    try {
      const response = await userSvc.getUserById(Number(user.id_user));
      SetTokens(response.data.data.emrv2s);
      //   setFilteredAssets(data.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchEMR();
  }, []);

  //   useEffect(() => {
  //     // Filter assets based on the search term
  //     const filtered = assets.filter((asset) => {
  //       const lowerCaseSearchTerm = searchTerm.toLowerCase();
  //       return (
  //         asset?.name.toLowerCase().includes(lowerCaseSearchTerm) ||
  //         asset?.kode.toLowerCase().includes(lowerCaseSearchTerm)
  //       );
  //     });

  //     setFilteredAssets(filtered);
  //   }, [searchTerm, assets]);

  const customPaddingStyle = {
    padding: "0.75rem 0.5rem",
  };

  return (
    <div className="main-content-page">
      <Card border="light" className="shadow-sm">
        <Card.Header className="pb-0">
          <Card.Title className="">Token EMR</Card.Title>
          <Row className="align-items-center mt-3">
            <Form>
              <div className="mb-2">
                <Form.Group id="range">
                  <Row
                    style={{ width: "100%", margin: "0 3px" }}
                    className="fs-6"
                  >
                    <Col
                      style={{
                        minWidth: "125px",
                        height: "54px",
                        padding: "0 3px 0 0",
                      }}
                    >
                      {/* <Search
                        ref={searchInputRef}
                        type="text"
                        placeholder="Cari ATM & ID ATM..."
                        className="me-1"
                        value={searchTerm}
                        size="large"
                        style={{
                          height: "48px",
                          borderColor: "#000", // Set default border color
                          outline: "none", // Remove outline on focus
                          ":focus": {
                            borderColor: "#00aaff", // Change border color on focus
                          },
                        }}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      /> */}
                      <FormControl
                        ref={searchInputRef}
                        type="text"
                        placeholder="Cari Token EMR"
                        className="me-1"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </div>
            </Form>
          </Row>
        </Card.Header>

        <Card.Body style={{ overflowX: "auto", display: "visible" }}>
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "300px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th
                    className="border-0 d-flex justify-content-center"
                    style={customPaddingStyle}
                  >
                    Token EMR
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "300px" }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              ) : tokens.length > 0 ? (
                <tbody>
                  {tokens.map((token, index) => (
                    <tr key={index}>
                      <td className="border-1 " style={customPaddingStyle}>
                        <a
                          onClick={() => history.push(`/emrv2/${token.token}`)}
                          rel="noopener noreferrer"
                          className="fw-bolder h-full w-full d-block bg-grey hover-bg-grey"
                          style={{
                            width: "100%",
                            height: "100%",
                            color: "black",
                            whiteSpace: "pre-line",
                          }}
                        >
                          <span
                            className={`fw-bolder ${
                              isExpired(token.start_date, token.end_date)
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {token?.token}
                          </span>
                          {/* <span
                            className={`fw-bolder ${
                              isExpired(token.start_date, token.end_date)
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {isExpired(token.start_date, token.end_date)
                              ? "Kadaluarsa"
                              : "Aktif"}
                          </span> */}

                          <br />
                          {`${token?.start_date} - ${token?.end_date}`}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-center"
                    style={{ height: "200px" }}
                  >
                    <span className="text-muted">Tidak ada token EMR</span>
                  </td>
                </tr>
              )}
            </Table>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
